<template>
  <div>
    <v-dialog
      v-model="autoSelectionStatus"
      width="90vw"
      max-width="90vw"
      content-class="module__auto-selection"
      scrollable
    >
      <a class="module__enter-client-close" @click="changeAutoSelectionsPopup"></a>
      <div class="module__auto-selection-inner">
        <div class="module__auto-selection-title">
          <p>{{ staticText.autoSelection.title[actualLang.index] }}</p>
        </div>

        <div v-if="loginMessage !== ''" class="module__auto-selection-content">
          <p class="not_manager">{{ loginMessage[actualLang.index] }}</p>
        </div>
        <div v-else class="module__auto-selection-content">
          <form class="module__auto-selection-form" @submit.prevent="getAutoSelect">
            <AutoSelectionCriterion
              v-for="(item, index) in criterionCount"
              :key="item.id"
              :item="item"
              :index="index+1"
              :in-pending="isLoading"
              @removeCriterion="deleteCriterion"
              @removeBrand="getFirstBrand(true)"
              @in-pending="showLoader"
            />
            <div class="module__auto-selection-btn-wrap">
              <BaseCheckbox
                :value="priceRangeComputed"
              />
              <div v-if="criterionCount.length < 5" class="module__auto-selection-btn criterion">
                <a
                  class="submit-btn"
                  @click="addCriterion"
                >
                  {{ staticText.autoSelection.btnAddCount[actualLang.index] }}
                </a>
              </div>
              <div v-if="notEmptyObject(criterionCount)" class="module__auto-selection-btn">
                <button
                  v-if="criterionCount[0].dataFilter.subcat"
                  class="submit-btn"
                  type="submit"
                >
                  {{ staticText.autoSelection.btnGetProducts[actualLang.index] }}
                </button>
                <a
                  v-else
                  class="disabled"
                  @click.stop.prevent
                >
                  {{ staticText.autoSelection.btnGetProducts[actualLang.index] }}
                </a>
              </div>
            </div>
          </form>
          <AutoSelectionProduct />
          <div
            v-if="notEmptyObject(errorMessage)"
            class="module__auto-selection-product__error"
          >
            <p>{{ errorMessage[actualLang.index] }}</p>
          </div>
        </div>
        <div v-if="autoSelectionProducts.length"
             class="module__auto-selection-btn btn-add-items">
          <a
            class="submit-btn"
            @click="addToBasket"
          >
            {{ staticText.autoSelection.btn[actualLang.index] }}
          </a>
        </div>
      </div>
    </v-dialog>

    <v-overlay :value="isLoading" :z-index="10000" class="test">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { staticText } from '../../i18n';
import { baseDomainImg } from '../../constans';
import { store } from '../../store';
import AutoSelectionCriterion from './AutoSelectionСriterion.vue';
import AutoSelectionProduct from './AutoSelectionProduct.vue';

import 'vue-select/src/scss/vue-select.scss';
import BaseCheckbox from '../BaseCheckbox.vue';

export default {
  name: 'AutoSelectionPopup',
  components: {
    AutoSelectionCriterion,
    AutoSelectionProduct,
    BaseCheckbox,
  },
  data() {
    return {
      staticText,
      baseDomainImg,
      store,
      isLoading: false,
      products: {},
      criterionCount: [],
      criterionNext: 0,
      priceRange: {
        fieldName: 'price',
        range: {
          from: 100,
          to: 500000,
          step: 100,
          default: [100, 500000],
        },
        text: 'грн',
        type: 'measure',
        status: true,
      },
      errorMessage: [],
      firstBrand: '',
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
      autoSelectionStatus: (state) => state.autoSelection.autoSelectionStatus,
      autoSelectionProducts: (state) => state.autoSelection.autoSelectionProducts,
      loginMessage: (state) => state.autoSelection.loginMessage,
      basket: (state) => state.basket.basket,
    }),
    priceRangeComputed() {
      return { ...this.priceRange, title: this.staticText.autoSelection.priceRangeTitle };
    },
  },
  mounted() {
    this.addCriterion();
    store.commit('autoSelection/CLEAR_AUTO_SELECTION_CRITERION');
  },
  methods: {
    changeAutoSelectionsPopup() {
      this.$store.commit('autoSelection/SET_AUTO_SELECTION_POPUP');
      this.getFirstBrand(true);
    },
    addToBasket() {
      this.autoSelectionProducts.forEach((item) => {
        if (item.assortment) {
          this.$store.dispatch('basket/getProduct', {
            id: item.id,
            cb: (data) => {
              let { addedToBasket, sale, priceUAH, id } = data.totalInfo;

              let flag = true;

              const productBasket = JSON.parse(localStorage.productBasket || '[]');

              this.basket.forEach((elem, index) => {
                if (id === elem.totalInfo.id) {
                  flag = false;
                  elem.totalInfo.addedToBasket += 1;
                  this.$store.commit(
                    'basket/APPEND_TOTAL_PRICE',
                    sale ? sale : priceUAH,
                  );

                  productBasket[index].addedToBasket = elem.totalInfo.addedToBasket;

                  try {
                    localStorage.productBasket = JSON.stringify(productBasket);
                  } catch (e) {
                    alert('Очистите LocalStorage.');
                  }

                  return;
                }
              });

              if (flag) {
                data.totalInfo.addedToBasket += 1;
                const addedProduct = {
                  id: data.totalInfo.id,
                  addedToBasket: data.totalInfo.addedToBasket,
                };

                productBasket.push(addedProduct);
                try {
                  localStorage.productBasket = JSON.stringify(productBasket);
                } catch (e) {
                  alert('Очистите LocalStorage.');
                }

                this.$store.commit('basket/UPDATE_BASKET', data);
                this.$store.commit(
                  'basket/APPEND_TOTAL_PRICE',
                  data.totalInfo.sale
                    ? data.totalInfo.sale
                    : data.totalInfo.priceUAH,
                );
              }
            },
          });
        }
      });
      this.$store.commit('autoSelection/CLEAR_AUTO_SELECTION_PRODUCTS');
      this.$store.commit('autoSelection/SET_AUTO_SELECTION_POPUP');
    },
    addCriterion() {
      if (this.criterionCount.length < 5) {
        this.getFirstBrand();
        this.criterionCount.forEach((item) => {
          this.$set(item, 'disabledSelect', true);
        });
        this.criterionCount.push({
          id: this.criterionNext,
          dataFilter: {
            cat: '',
            subcat: '',
            brand: '',
            type: '',
            color: '',
          },
          disabledSelect: false,
        });
        this.criterionNext++;
      }
    },
    deleteCriterion(val) {
      this.criterionCount.splice(this.criterionCount.findIndex((item) => item.id === val), 1);
      this.$store.commit('autoSelection/CLEAR_AUTO_SELECTION_PRODUCTS');
      this.getFirstBrand();
      if (this.criterionCount.length === 0) {
        this.addCriterion();
      }
    },
    getAutoSelect() {
      const options = [];

      this.criterionCount.forEach((item) => options.push(item.dataFilter));
      this.$store.dispatch('autoSelection/getAutoSelectionProduct', {
        cb: (data) => {
          if (data.products) {
            store.commit('autoSelection/SET_AUTO_SELECTION_PRODUCTS', data.products);
          }
        },
        options: options,
        price: this.priceRange.range.default,
        error: (data) => {
          if (data.message) {
            this.errorMessage = data.message;
          }
        },
      });
    },
    getFirstBrand(clear = false) {
      this.firstBrand = '';
      if (!clear) {
        this.criterionCount.forEach((item) => {
          if (this.firstBrand === '' && this.notEmptyObject(item.dataFilter.brand)) {
            this.firstBrand = item.dataFilter.brand;
          }
        });
      }
      this.$store.dispatch('autoSelection/sendFirstBrand', {
        firstBrand: this.firstBrand,
        error: (data) => console.log(data),
      });
    },
    showLoader(arg) {
      this.isLoading = arg;
    },
    notEmptyObject(someObject) {
      return Object.keys(someObject).length;
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep .module__auto-selection {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  flex-direction: column;
}
.module__auto-selection-inner {
  padding: 46px 40px 22px 40px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
}
.module__enter-client-close {
  display: block;
  position: relative;
  &::before {
    top: 24px;
    right: 24px;
    content: '\e913';
    font-family: 'icomoon';
    color: #9b9b9b;
    font-size: 32px;
    position: absolute;
  }
}
.module__auto-selection-title {
  font-family: 'MagistralC';
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #1d1d1d;
  font-weight: bold;
  padding-bottom: 16px;
  text-align: center;
}
.module__auto-selection-content {
  height: auto;
  .not_manager{
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: $main;
    margin-top: 50px;
  }
}
.module__auto-selection-btn {
  padding-top: 0;
  margin-top: 15px;
  & a, button {
    border: 1px solid #a12c3a;
    border-radius: 4px;
    padding: 12px 16px 10px;
    font-family: 'MagistralC';
    font-size: 13px;
    line-height: 15px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: #a12c3a;
    user-select: none;
    white-space: nowrap;
    min-width: 270px;
    &.disabled{
      border: 1px solid #9b9b9b;
      color: #9b9b9b;
      &:hover {
        background-color: transparent;
        color: #9b9b9b;
        border: 1px solid #9b9b9b;
        cursor: not-allowed;
      }
    }
    &:hover {
      background-color: $black;
      color: $white;
      border: 1px solid $black;
    }
  }
}

.btn-add-items {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0 10px;
}

.module__auto-selection-form {
  display: flex;
  flex-wrap: wrap;
  .module__auto-selection-select {
    max-width: 520px;
    font-size: 16px;
    line-height: 22px;
    width: 20%;
    color: #1d1d1d;
    .vs__dropdown-toggle {
      border: 1px solid #9b9b9b;
      border-radius: 4px;
      padding-bottom: 0px;
      height: 56px;
      .vs__selected-options {
        display: flex;
        height: 56px;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 15px;
        .vs__selected {
          padding-left: 12px;
          padding-right: 12px;
          margin: 0px;
          height: 56px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .vs__search {
          padding-left: 12px;
          padding-right: 12px;
          margin: 0px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: auto;
          scrollbar-color: #262626 #fff;
          scrollbar-width: thin;
          &::-webkit-scrollbar {
            background-color: #fff;
            height: 4px;
          }
          &::-webkit-scrollbar-track {
            background-color: #fff;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #262626;
          }
        }
      }
      .vs__actions {
        position: relative;
        padding-right: 30px;
        &::after {
          position: absolute;
          font-size: 10px;
          color: #1d1d1d;
          content: '\e92e';
          font-family: 'icomoon';
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
          z-index: 2;
        }
        & svg {
          display: none;
        }
      }
    }
    .vs__dropdown-menu {
      .vs__dropdown-option {
        white-space: normal;
        /*text-overflow: ellipsis;*/
        /*overflow: hidden;*/
      }
      .vs__dropdown-option--highlight {
        background: $gold;
        color: #fff;
      }
    }
  }
  .module__auto-selection-btn-wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    div.module__auto-selection-btn{
      display: flex;
      align-items: center;
      justify-content: center;
      &.end{
        justify-content: flex-end;
      }
    }
    ::v-deep .checkbox-wrapper{
      padding-left: 0;
      margin: 0;
      max-width: 350px;
      .config-sidebar_title{
        padding: 0;
        margin-bottom: 15px;
        .icon-project-minus{
          &::before{
            display: none;
          }
        }
      }
      .checkboxes-range{
        .price{
          display: none;
        }
      }
    }
  }
}

.module__auto-selection-product__error{
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  p {
    font-size: 18px;
    text-align: center;
    padding-top: 50px;
  }
}
.module__auto-selection-btn {
  padding-top: 0;
  margin-top: 15px;
  & a, button {
    border: 1px solid #a12c3a;
    border-radius: 4px;
    padding: 12px 16px 10px;
    font-family: 'MagistralC';
    font-size: 13px;
    line-height: 15px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: #a12c3a;
    user-select: none;
    white-space: nowrap;
    min-width: 270px;
    &.disabled{
      border: 1px solid #9b9b9b;
      color: #9b9b9b;
      &:hover {
        background-color: transparent;
        color: #9b9b9b;
        border: 1px solid #9b9b9b;
        cursor: not-allowed;
      }
    }
    &:hover {
      background-color: $black;
      color: $white;
      border: 1px solid $black;
    }
  }
}
@media (max-width: 1100px) {
  .module__auto-selection-btn {
    padding-top: 0;
    margin-top: 15px;
    & a, button {
      border: 1px solid #a12c3a;
      border-radius: 4px;
      padding: 12px 12px 10px;
      font-size: 12px;
      min-width: 220px;
    }
  }
}
@media (max-width: 850px) {
  .module__auto-selection-inner {
    padding: 46px 20px 22px 20px;
  }
  .module__auto-selection-form {
    .module__auto-selection-btn-wrap{
      width: 100%;
      display: flex;
      flex-direction: column;
      ::v-deep .checkbox-wrapper{
        padding-left: 0;
        margin: 0;
        max-width: 100%;
        order: 2;
        margin-top: 15px;
        .config-sidebar_title{
          text-align: center;
        }
        .checkboxes-range{
          max-width: 80%;
          margin-left: 10%;
        }
      }
      div.module__auto-selection-btn{
        order: 3;
        width: 100%;
        &.criterion{
          order: 1;
        }
        & ::v-deep a, button{
          width: 80%;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  ::v-deep .module__auto-selection {
    .module__enter-client-close {
      &::before {
        top: 4px;
        right: 10px;
      }
    }
  }
  .module__auto-selection-btn-wrap{
    flex-direction: column;
    margin-bottom: 20px;
  }
  .module__auto-selection-form {
    .module__auto-selection-select {
      .vs__dropdown-toggle {
        height: 66px;
        .vs__selected-options {
          height: 66px;
          .vs__selected {
            height: 66px;
          }
        }
      }
    }
    div.module__auto-selection-btn{
      order: 3;
      width: 100%;
      &.criterion{
        order: 1;
      }
      & ::v-deep a, button{
        width: 80%;
      }
    }
  }

}
</style>
