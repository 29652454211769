<template>
  <div>
    <div class="training__registration-title">
      {{ staticText.training.registration.regTitle[actualLang.index] }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { staticText } from '../../i18n';

export default {
  name: 'TrainingRegistrationTitle',
  components: {},
  data() {
    return {
      staticText,
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
    }),
  },
};
</script>

<style lang="scss">
.training__registration-title {
  font-family: 'MagistralC';
  font-size: 32px;
  line-height: 37px;
  letter-spacing: 0.02em;
  color: #1d1d1d;
  padding-bottom: 40px;
  position: relative;
  font-weight: bold;
  & button {
    padding-right: 17px;
    color: #9b9b9b;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    &:before {
      transition: all 0.3s;
    }
    &:hover:before {
      color: #000;
    }
  }
}

@media (max-width: 587px) {
  .training__registration-title {
    font-size: 25px;
  }
}
@media (max-width: 470px) {
  .training__registration-title {
    font-size: 19px;
  }
}
@media (max-width: 370px) {
  .training__registration-title {
    font-size: 16px;
  }
}
</style>
