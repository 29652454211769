import Vue from 'vue';
import VueGtm from 'vue-gtm';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import { store } from './store';
import './router/api';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueCookies);

/* https://github.com/mib200/vue-gtm */
Vue.use(VueGtm, {
  id: 'GTM-NBWBTBL', // or ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  /*
  queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
    gtm_preview:'env-4',
    gtm_cookies_win:'x'
  },
  */
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // If router, you can exclude some routes name (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  vuetify,
  router,
  store,
  VueCookies,
  render: (h) => h(App),
}).$mount('#app');
