import { baseDomain } from '../../constans';

const personalPromo = {
  namespaced: true,
  state: { // состояние приложения, данные, которые внутри приложения используются
    personalPromo: [],
    countPage: null,
    allBrands: {},
    archivePersonalPromo: [],
    archiveCountPage: null,
    archiveAllBrands: {},
  },
  mutations: { // нужны для того, чтобы описывать методы, которые будут изменять данные нашего приложения
    SET_PERSONALPROMO(state, data) {
      data.personalPromo.forEach((e) => {
        e.additionalInfo.open = false;
      });

      state.personalPromo = data.personalPromo;
      state.countPage = data.countPage;
      state.allBrands = data.brands;
    },
    SET_ARCHIVE_PROMO(state, data) {
      data.personalPromo.forEach((e) => {
        e.additionalInfo.open = false;
      });

      state.archivePersonalPromo = data.personalPromo;
      state.archiveCountPage = data.countPage;
      state.archiveAllBrands = data.brands;
    },
  },
  actions: {
    async getPersonalPromo({ commit, dispatch }, { page = 1, PER_PAGE = 16, brand = '', archive = false, cb, error }) {
      const token = localStorage.alcotecUserInfo &&
        JSON.parse(localStorage.alcotecUserInfo).user &&
        JSON.parse(localStorage.alcotecUserInfo).user.token || '';

      fetch(`${baseDomain}/actions`, {
        method: 'POST',
        headers: {
          'auth-token': token,
        },
        body: JSON.stringify({
          page: page,
          perPage: PER_PAGE,
          brand: brand,
          archive: archive,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', { action: 'personalPromo/getPersonalPromo', page, PER_PAGE, brand, archive, cb, error }, { root: true });
          } else if (response.success) {
            if (archive) {
              commit('SET_ARCHIVE_PROMO', response);
            } else {
              commit('SET_PERSONALPROMO', response);
            }
            cb(response);
          } else {
            error(response);
          }
        })
        .catch((err) => console.error(err));
    },
  },
};

export default personalPromo;
