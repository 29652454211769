<template>
  <section class="card__product">
    <div class="card__container">
      <div class="card__product-main__wrapper">
        <div class="card__product-container">
          <div class="card__search-wrapper">
            <Search />
          </div>
          <div class="card__product-wrapper" :class="{ margin_disabled: !product.totalInfo.marginSuccess }">
            <Slider
              :product="product"
              @addSet="addSet"
            />
            <div class="card__product-characteristic">
              <InfoProduct ref="infoProduct" :product="product" />
              <Tabs :product="product" />
            </div>
          </div>
        </div>
        <CollectionGoods v-if="product.collections" />
        <OtherGoods v-if="product.products" />
      </div>
      <RightSidebar class="card__product-wrapper_sidebar" />
    </div>
    <Compare />
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { store } from '../store';

import Slider from '../components/Product/Slider.vue';
import InfoProduct from '../components/Product/InfoProduct.vue';
import Tabs from '../components/Product/Tabs.vue';
import OtherGoods from '../components/OtherGoods.vue';
import RightSidebar from '../components/Configurator/RigthSidebar.vue';
import CollectionGoods from '../components/CollectionGoods.vue';
import Compare from '../components/Compare.vue';
import Search from '../components/Configurator/Search.vue';

export default {
  name: 'Product',
  components: {
    Slider,
    Tabs,
    InfoProduct,
    OtherGoods,
    RightSidebar,
    CollectionGoods,
    Compare,
    Search,
  },
  computed: {
    ...mapState({
      product: (state) => state.products.product,
      isLoading: (state) => state.products.isLoading,
      itemsCompare: (state) => state.categoryItem.categoryItemCompare.products,
      sidebarStatus: (state) => state.profiles.profileConfigure.rigthSidebarStatus,
    }),
  },
  created() {
    this.$store.commit('profiles/CLOSE_SIDEBAR');
    if (this.itemsCompare.length) {
      const { id: productId } = this.product.totalInfo;
      const statusProductCompare = this.itemsCompare.find(
        (item) => item.totalInfo.id === productId,
      );

      if (statusProductCompare) {
        this.product.totalInfo.compare = true;
      } else {
        this.product.totalInfo.compare = false;
      }
    }

    const category = this.$store.getters['profiles/categoryName'](
      this.$route.params.categoryId,
    );

    if (!category) {
      this.$router.push('/404-error');
    }
  },
  methods: {
    addSet(items) {
      items.forEach((e) => {
        this.$refs.infoProduct.addToBasket(e);
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    store.dispatch('products/getProduct', {
      id: to.params.productId,
      cb() {
        next();
      },
      error() {
        next('/404-error');
      },
    });
  },
};
</script>

<style lang="scss">
.margin_disabled{
  opacity: 0.6;
}
.card__product {
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  .card__container {
    width: 100%;
    display: flex;
    .card__product-main__wrapper {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
.card__product-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
}
.card__product-wrapper {
  display: flex;
  padding-top: 50px;
  padding-bottom: 60px;
  flex-wrap: wrap;
  position: relative;
}
.card__product-slider {
  max-height: 575px;
}
.order-sidebar.card__product-wrapper_sidebar{
  position: absolute;
  right: 0;
  height: 75vh;
}
.order-sidebar.card__product-wrapper_sidebar.active{
  position: fixed;
  right: 0;
  height: 100vh;
}
//.sidebar__order-closed.card__product-wrapper_sidebar {
//  height: 720px !important;
//  right: -1px;
//  margin-left: 0px;
//}
//.sidebar__order-closed.card__product-wrapper_sidebar.active {
//  position: sticky !important;
//}
//.order-sidebar.card__product-wrapper_sidebar {
//  top: 0px;
//  right: -1px;
//  z-index: 10;
//  margin-top: 0px;
//}
//.order-sidebar.card__product-wrapper_sidebar.added-product-aside {
//  margin-top: 8px;
//}
//.order-sidebar.card__product-wrapper_sidebar.added-product-aside.active {
//  margin-top: 0px;
//}
//.order-sidebar.card__product-wrapper_sidebar {
//  position: fixed !important;
//}
//.order-sidebar.card__product-wrapper_sidebar.active {
//  position: fixed !important;
//}
//.order-sidebar.card__product-wrapper_sidebar.added-product-aside {
//  height: 100vh !important;
//  margin-top: 0px;
//}
//.card__product-wrapper_sidebar {
//  position: sticky !important;
//  top: 0px;
//  right: 0;
//  z-index: 1;
//  height: auto !important;
//  .sidebar__order-open-wrapper {
//    height: auto;
//  }
//}
@media (max-width: 1275px) {
  .card__product-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .card__product-characteristic {
    margin-top: 30px;
  }
}
@media (max-width: 980px) {
  .card__product-wrapper {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .order-sidebar.card__product-wrapper_sidebar{
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh !important;
  }
}
@media (max-width: 534px) {
  .card__product {
    margin-bottom: 77px;
  }
}
</style>
<style lang="scss" scoped>
.card__search-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  & ::v-deep .configurator-search__wrapper{
    margin-right: 0;
  }
}
@media (min-width: 1275px) {
  .card__search-wrapper{
    & ::v-deep .configurator-search__wrapper{
      max-width: 50%;
      & button{
        max-width: 100%;
        & .search{
          max-width: 100%;
        }
      }
    }
  }
}
</style>
