<template>
  <div class="training__registration-wrap">
    <div class="training__registration-membere">
      <div><img :src="`${baseDomainImg}/public/images/static-page/learning/training-800x350.png`" alt /></div>
      <div class="training__registration-membere-title">
        <p>{{ staticText.training.registration.regRightDescriptionOne[actualLang.index] }}</p>
        <p>{{ staticText.training.registration.regRightDescriptionTwo[actualLang.index] }}</p>
      </div>
      <div class="training__registration-membere-subtitle">
        {{ staticText.training.registration.regRightSubtitle[actualLang.index] }}
      </div>
      <div class="training__registration-membere-inner">
        <div class="training__registration-membere-inner-left">
          <ul>
            <li>
              {{ staticText.training.registration.regRightPointOne[actualLang.index] }}
            </li>
            <li>
              {{ staticText.training.registration.regRightPointTwo[actualLang.index] }}
            </li>
            <li>
              {{ staticText.training.registration.regRightPointThree[actualLang.index] }}
            </li>
          </ul>
        </div>
        <!--<div class="training__registration-membere-inner-right">
          <ul>
            <li>
              {{ staticText.training.registration.regRightPointOne[actualLang.index] }}
            </li>
            <li>
              {{ staticText.training.registration.regRightPointTwo[actualLang.index] }}
            </li>
            <li>
              {{ staticText.training.registration.regRightPointThree[actualLang.index] }}
            </li>
          </ul>
        </div>-->
      </div>
      <iframe style="margin:10px;" width="45%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLbP3MzJnkL8Bg2l__N6KDCYJ9nNPWgZgv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe style="margin:10px;" width="45%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLbP3MzJnkL8C3jauptfghNOKMVCV2QKWL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe style="margin:10px;" width="45%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLbP3MzJnkL8DAW5bxzMif8EkzSe3uavFn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe style="margin:10px;" width="45%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLbP3MzJnkL8DS2u4M2N-zxQrjmbfXVMJx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe style="margin:10px;" width="45%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLbP3MzJnkL8D2EbptVgwO9mzBvPoKo00M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { staticText } from '../../i18n';
import { baseDomainImg } from '../../constans';

export default {
  name: 'TrainingRegistrationRight',
  data() {
    return {
      staticText,
      baseDomainImg,
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
    }),
  },
};
</script>

<style lang="scss">
.training__registration-wrap{
  width: calc(100% / 3 * 2);
  padding-left: 70px;
}
.training__registration-membere {
  max-width: 855px;
  width: 100%;
  margin-top: 0;
}
.training__registration-membere-title {
  font-size: 16px;
  line-height: 26px;
  color: #1d1d1d;
  width: 100%;
  max-width: 658px;
  & p{
    padding-bottom: 10px;
  }
}
.training__registration-membere-subtitle {
  padding-top: 38px;
  padding-bottom: 27px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #1d1d1d;
}
.training__registration-membere-inner {
  display: flex;
  font-size: 16px;
  line-height: 26px;
  justify-content: space-between;
  color: #1d1d1d;
  & li {
    padding-bottom: 26px;
  }
}
.training__registration-membere-inner-left,
.training__registration-membere-inner-right {
  width: 100%;
  & ul {
    & li {
      position: relative;
      &::before {
        position: absolute;
        color: rgba(220, 196, 136, 0.4);
        top: 5px;
        left: 0;
        content: '\e900';
        font-family: 'icomoon';
        font-size: 40px;
      }
    }
  }
}
.training__registration-membere-inner-left {
  padding-right: 35px;
  & ul {
    & li:nth-child(2)::before {
      content: '\e901';
      font-size: 54px;
      top: 0;
    }
    & li:nth-child(3)::before {
      content: '\e902';
      font-size: 54px;
      top: 0;
    }
    & li:nth-child(4)::before {
      content: '\e903';
      font-size: 54px;
      top: 0;
    }
  }
}
.training__registration-membere-inner-right {
  padding-left: 13px;
  & ul {
    & li:nth-child(1)::before {
      content: '\e904';
      font-size: 54px;
      top: 0;
    }
    & li:nth-child(2)::before {
      content: '\e905';
      font-size: 54px;
      top: 0;
    }
    & li:nth-child(3)::before {
      content: '\e906';
      font-size: 54px;
      top: 0;
    }
  }
}
@media (max-width: 1430px) {
  .training__registration-membere-inner {
    flex-direction: column;
  }
  .training__registration-membere-inner-left,
  .training__registration-membere-inner-right {
    padding: 0px;
  }
}
@media (max-width: 1240px) {
  .training__registration-wrap{
    width: 100%;
    padding-left: 0;
    order: 1;
  }
  .training__registration-membere {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 0;
    .training__registration-membere-title {
      max-width: 100%;
    }
  }
}
</style>
