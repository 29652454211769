<template>
  <h1>Info Promo</h1>
</template>

<script>
export default {
  name: 'InfoPromo',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
