<template>
  <div v-if="notEmptyObject(option)" class="module__auto-selection-row">
    <div class="index">
      <span>{{index}}</span>
    </div>
    <vSelect
      v-model="item.dataFilter.cat"
      :placeholder="staticText.autoSelection.inputsPlaceholders.cat[actualLang.index]"
      :searchable="false"
      :clearable="false"
      :loading="inPending"
      :options="option.categories[actualLang.index]"
      label="title"
      :value="item.dataFilter.cat.title"
      :disabled="item.disabledSelect"
      class="module__auto-selection-select"
      @input="getCriterion('cat')"
    />
    <vSelect
      v-model="item.dataFilter.subcat"
      :placeholder="staticText.autoSelection.inputsPlaceholders.subCat[actualLang.index]"
      :searchable="false"
      :clearable="false"
      :loading="inPending"
      :options="option.sub_categories[actualLang.index]"
      label="title"
      :value="item.dataFilter.subcat.title"
      :disabled="item.disabledSelect"
      class="module__auto-selection-select"
      @input="getCriterion('subcat')"
    />
    <div
      class="module__auto-selection-select__clearable"
      :class="{ active: activeClass('brand')}"
    >
      <vSelect
        v-model="item.dataFilter.brand"
        :placeholder="staticText.autoSelection.inputsPlaceholders.brand[actualLang.index]"
        :searchable="false"
        :clearable="false"
        :loading="inPending"
        :options="option.brands[actualLang.index]"
        label="title"
        :value="item.dataFilter.brand.title"
        :disabled="item.disabledSelect"
        class="module__auto-selection-select"
        @input="getCriterion('brand')"
      />
      <a v-if="activeClass('brand')" class="clear" @click="clearField('brand')">&#215;</a>
    </div>
    <div
      class="module__auto-selection-select__clearable"
      :class="{ active: activeClass('type')}"
    >
      <vSelect
        v-model="item.dataFilter.type"
        :placeholder="staticText.autoSelection.inputsPlaceholders.type[actualLang.index]"
        :searchable="false"
        :clearable="false"
        :loading="inPending"
        :options="option.types[actualLang.index]"
        label="title"
        :value="item.dataFilter.type.title"
        :disabled="item.disabledSelect"
        class="module__auto-selection-select"
        @input="getCriterion('type')"
      />
      <a v-if="activeClass('type')" class="clear" @click="clearField('type')">&#215;</a>
    </div>
    <div
      class="module__auto-selection-select__clearable"
      :class="{ active: activeClass('color')}"
    >
      <vSelect
        v-model="item.dataFilter.color"
        :placeholder="staticText.autoSelection.inputsPlaceholders.color[actualLang.index]"
        :searchable="false"
        :clearable="false"
        :loading="inPending"
        :options="option.colors[actualLang.index]"
        label="title"
        :value="item.dataFilter.color.title"
        :disabled="item.disabledSelect"
        class="module__auto-selection-select"
        @input="getCriterion('color')"
      />
      <a v-if="activeClass('color')" class="clear" @click="clearField('color')">&#215;</a>
    </div>
    <div class="btn-delete">
      <a
        @click="$emit('removeCriterion', item.id)"
      >
        &#215;
      </a>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapState } from 'vuex';
import { staticText } from '../../i18n';
import { baseDomainImg } from '../../constans';
import { store } from '../../store';

import 'vue-select/src/scss/vue-select.scss';

export default {
  name: 'AutoSelectionCriterion',
  components: {
    vSelect,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    inPending: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      staticText,
      baseDomainImg,
      store,
      option: [],
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
      autoSelectionCriterion: (state) => state.autoSelection.autoSelectionCriterion,
    }),
  },
  watch: {
    option: {
      handler(value) {
        Object.keys(value).forEach((item) => {
          if (item === 'brands') {
            if (value[item][this.actualLang.index].length === 1) {
              this.$set(this.item.dataFilter, 'brand', value[item][this.actualLang.index][0]);
            }
          }
        });
      },
    },
  },
  mounted() {
    this.getCriterion();
    store.commit('autoSelection/CLEAR_AUTO_SELECTION_PRODUCTS');
  },
  methods: {
    criterionRequest() {
      this.$store.dispatch('autoSelection/getAutoSelectionCriterion', {
        cb: (response) => {
          this.option = response;
          this.$emit('in-pending', false);
        },
        cat: this.item.dataFilter.cat.id ? this.item.dataFilter.cat.id : '',
        subcat: this.item.dataFilter.subcat.id ? this.item.dataFilter.subcat.id : '',
        brand: this.item.dataFilter.brand.id ? this.item.dataFilter.brand.id : '',
        type: this.item.dataFilter.type.id ? { id: this.item.dataFilter.type.id, field: this.item.dataFilter.type.field } : '',
        color: this.item.dataFilter.color.id ? { id: this.item.dataFilter.color.id, field: this.item.dataFilter.color.field } : '',
        error: (response) => {
          this.$store.commit('autoSelection/SET_LOGIN_MESSAGE', response);
          this.$emit('in-pending', false);
        },
      });
    },
    getCriterion(type) {
      if (!this.inPending) {
        this.$emit('in-pending', true);
        if (type === 'cat') {
          this.$set(this.item.dataFilter, 'subcat', '');
          this.$set(this.item.dataFilter, 'brand', '');
          this.$set(this.item.dataFilter, 'type', '');
          this.$set(this.item.dataFilter, 'color', '');
        } else if (type === 'subcat') {
          this.$set(this.item.dataFilter, 'brand', '');
          this.$set(this.item.dataFilter, 'type', '');
          this.$set(this.item.dataFilter, 'color', '');
        }
        this.criterionRequest();
      }
    },
    notEmptyObject(someObject) {
      return Object.keys(someObject).length;
    },
    clearField(type) {
      if (!this.inPending) {
        this.$emit('in-pending', true);
        if (type === 'brand' && this.item.dataFilter.brand !== '') {
          this.$set(this.item.dataFilter, 'brand', '');
          this.$set(this.item.dataFilter, 'type', '');
          this.$set(this.item.dataFilter, 'color', '');
          this.$emit('removeBrand');
        } else if (type === 'type' && this.item.dataFilter.type !== '') {
          this.$set(this.item.dataFilter, 'type', '');
          this.$set(this.item.dataFilter, 'color', '');
        } else if (type === 'color' && this.item.dataFilter.color !== '') {
          this.$set(this.item.dataFilter, 'color', '');
        }
        this.criterionRequest();
      }
    },
    activeClass(type) {
      let flag = false;

      if (type === 'brand' && this.item.dataFilter.brand !== '' && !this.item.disabledSelect) {
        flag = true;
      } else if (type === 'type' && this.item.dataFilter.type !== '' && !this.item.disabledSelect) {
        flag = true;
      } else if (type === 'color' && this.item.dataFilter.color !== '' && !this.item.disabledSelect) {
        flag = true;
      }

      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>

.module__auto-selection-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
  .module__auto-selection-select {
    max-width: 520px;
    font-size: 16px;
    line-height: 22px;
    width: 18%;
    margin-right: .5%;
    color: #1d1d1d;
    ::v-deep .vs__dropdown-toggle {
      border: 1px solid #9b9b9b;
      border-radius: 4px;
      padding-bottom: 0px;
      height: 34px;
      width: 100%;
      max-width: 100%;
      .vs__selected-options {
        display: flex;
        height: 34px;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 15px;
        .vs__search {
          margin-top: 0;
          &::placeholder{
            opacity: 0.5;
          }

        }
        .vs__selected {
          padding-left: 12px;
          padding-right: 12px;
          margin: 0px;
          height: 34px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .vs__actions {
        position: relative;
        padding-right: 15px;
        &::after {
          position: absolute;
          font-size: 10px;
          color: #1d1d1d;
          content: '\e92e';
          font-family: 'icomoon';
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
          z-index: 2;
        }
        & svg {
          display: none;
        }
        & .vs__clear{
          width: 10px;
          height: 10px;
          &::after{
            position: absolute;
            font-size: 18px;
            color: #1d1d1d;
            content: '\00D7';
            top: 50%;
            transform: translateY(-50%);
            left: 18px;
            z-index: 2;
          }
        }
        & .vs__spinner {
          border-radius: 50%;
          width: 4em;
          height: 4em;
          position: absolute;
          right: 3px;
          top: 5px;
        }
      }
    }
    ::v-deep .vs__dropdown-menu {
      height: auto;
      .vs__dropdown-option {
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .vs__dropdown-option--highlight {
        background: $main;
        color: #fff;
      }
    }
  }
  .module__auto-selection-select__clearable{
    max-width: 520px;
    font-size: 16px;
    line-height: 22px;
    width: 18%;
    margin-right: .5%;
    color: #1d1d1d;
    display: flex;
    flex-wrap: wrap;
    .module__auto-selection-select{
      width: 100%;
      ::v-deep .vs__dropdown-toggle {
        .vs__actions {
          padding-right: 15px;
        }
      }
      &.vs--disabled{
        cursor: not-allowed;
        ::v-deep .vs__dropdown-toggle {
          cursor: not-allowed;
          .vs__selected-options{
            cursor: not-allowed;
            input{
              cursor: not-allowed;
            }
          }
        }
          .vs__actions {
            cursor: not-allowed;
          }
      }
    }
  }
  .module__auto-selection-select__clearable{
    &.active{
      .module__auto-selection-select{
        width: 100%;
        ::v-deep .vs__dropdown-toggle {
          .vs__actions {
            padding-right: 25px;
            & .vs__spinner {
              right: 13px;
            }
          }
        }
      }
      .clear{
        margin-left: -18px;
        justify-self: center;
        font-size: 18px;
        line-height: 33px;
        z-index: 2;
        &:hover{
          color: $main;
        }
      }
    }
  }
  div.btn-delete {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 0;
    width: 3%;
    height: 34px;
    & a, button {
      border: 1px solid #a12c3a;
      border-radius: 4px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: #a12c3a;
      user-select: none;
      height: 34px;
      width: 34px;
      align-items: center;
      justify-content: center;
      display: flex;
      &.disabled{
        border: 1px solid #9b9b9b;
        color: #9b9b9b;
        &:hover {
          background-color: transparent;
          color: #9b9b9b;
          border: 1px solid #9b9b9b;
          cursor: not-allowed;
        }
      }
      &:hover {
        background-color: $black;
        color: $white;
        border: 1px solid $black;
      }
    }
  }
  .index {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0;
    width: 3%;
    margin-right: .5%;
    height: 34px;
    span{
      border: 1px solid #9b9b9b;
      border-radius: 4px;
      line-height: 1.4;
      font-size: 1em;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: #9b9b9b;
      user-select: none;
      height: 34px;
      width: 34px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}
@media (max-width: 850px) {
  .module__auto-selection-row {
    flex-direction: column;
    margin-bottom: 20px;
    .module__auto-selection-select {
      width: 100%;
      margin-bottom: 5px;
      margin-right: 0;
      align-self: center;
      .vs__dropdown-toggle {
        height: 66px;
        .vs__selected-options {
          height: 66px;
          .vs__selected {
            height: 66px;
          }
        }
      }
    }
    .module__auto-selection-select__clearable {
      width: 100%;
      margin-bottom: 5px;
      align-self: center;
      margin-right: 0;
      .vs__dropdown-toggle {
        height: 66px;
        .vs__selected-options {
          height: 66px;
          .vs__selected {
            height: 66px;
          }
        }
      }
    }
    div.btn-delete{
      width: 100%;
      max-width: 520px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      & a, button {
        width: 100%;

      }
    }
    .index {
      width: 100%;
      max-width: 520px;
      margin-right: 0;
      margin-bottom: 5px;
      height: 34px;
      align-items: center;
      justify-content: center;
      align-self: center;
      span{
        width: 100%;
      }
    }
  }

}
</style>
