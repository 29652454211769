<template>
  <div class="card__slider-wrap">
    <div v-if="product.totalInfo.madeIn.success" class="card__product-made-in">
      <img :src="`${baseDomainImg}${product.totalInfo.madeIn.img}`" alt />
      <p>{{ staticText.slider.madeIn }}</p>
      <p>{{product.totalInfo.madeIn.country}}</p>
    </div>
    <slick
      v-if="product.img.length > 1"
      ref="slick"
      class="card__product-slider"
      :options="slickOptions"
    >
      <div v-for="(slide, index) in product.img" :key="slide.id">
        <div v-if="!slide.video" class="card__product-slider-img">
          <img
            :src="`${baseDomainImg}${slide.url}`"
            alt
            @click="showModalIndex(index)"
          />
        </div>
        <div v-else class="card__product-slider-video">
          <iframe
            :src="`https://www.youtube.com/embed/${slide.video}`"
            marginheight="0"
            marginwidth="0"
            scrolling="no"
            allow="autoplay"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </slick>
    <div v-else class="card__product-slider no-previews">
      <div class="card__product-slider-img">
        <img :src="`${baseDomainImg}${product.img[0].url}`" alt="" />
      </div>
    </div>

    <div
      class="product-instruction"
      :class="{ onePhoto: product.img.length === 1 }"
    >
      <a
        v-if="product.totalInfo.urlInstruction"
        :href="`${baseDomainImg}${product.totalInfo.urlInstruction}`"
        download=""
      >
        <span class="icon-pdf-icon specifications__img"></span>
        {{ staticText.slider.btn[actualLang.index] }}
      </a>
      <a v-else href="#" class="disable" @click.prevent="">
        <span class="icon-pdf-icon specifications__img"></span>
        {{ staticText.slider.btn[actualLang.index] }}
      </a>
    </div>
    <div v-if="Array.isArray(product.extendedDiscount) && product.extendedDiscount.length > 0"
         class="product-extension"
    >
      <div class="card__product-extension-header">
        <h3>{{ staticText.product.setSliderTitle[actualLang.index] }}</h3>
      </div>
      <slick
        ref="extension"
        class="card__product-slider extension"
        :options="slickExtension"
      >
        <div v-for="item in product.extendedDiscount" :key="item.id">
          <div class="card__product-extension-slider">
            <router-link
              :to="{
                path: isLoggedIn
                  ? `/profile/configurator/category/${product.totalInfo.categoryId}/product/${product.totalInfo.id}`
                  : `/catalog/category/${product.totalInfo.categoryId}/product/${product.totalInfo.id}`,
              }"
              class="product"
            >
              <img
                :src="`${baseDomainImg}${product.img[0].url}`"
                alt
              />
              <p>
                <span>{{ product.totalInfo.brand }}</span> <span>{{ product.totalInfo.name }}</span>
              </p>
              <p>{{ product.totalInfo.sale ? product.totalInfo.sale : product.totalInfo.priceUAH }} грн.</p>
            </router-link>

            <div class="sign"><div class="circle"><span>+</span></div></div>
            <router-link
              :to="{
                path: isLoggedIn
                  ? `/profile/configurator/category/${item.categoryId}/product/${item.id}`
                  : `/catalog/category/${item.categoryId}/product/${item.id}`,
              }"
              class="product"
            >
              <img
                :src="`${baseDomainImg}${item.img}`"
                alt
              />
              <p><span>{{ item.brand }}</span> <span>{{ item.name }}</span></p>
              <p>{{ item.sale ? item.sale : item.priceUAH }} грн.</p>
            </router-link>

            <div class="sign"><div class="circle"><span>=</span></div></div>
            <div class="product">
              <p class="old-price">{{ item.oldPrice }} грн.</p>
              <p class="new-price">{{ item.setPrice }} грн.</p>
              <button
                v-if="!isLoggedIn"
                @click.prevent="addSet([product.totalInfo, item])"
              >
                <Button :text="staticText.product.buttons.buy[actualLang.index]" />
              </button>
              <button
                v-else-if="product.totalInfo.marginSuccess && product.totalInfo.marginSuccess === true"
                class="red-btn"
                @click.prevent="addSet([product.totalInfo, item])"
              >
                <Button :text="staticText.product.buttons.addSet[actualLang.index]" />
              </button>
              <span v-else class="not-available">{{ staticText.product.notAvailableSet[actualLang.index] }}</span>
            </div>
          </div>
        </div>
      </slick>
    </div>
    <ModalSlider
      v-if="showModal"
      :product="product"
      :index="index"
      @closeModal="closeModalEmit"
    />
  </div>
</template>

<script>
import Slick from 'vue-slick';
import { mapState } from 'vuex';
import { baseDomainImg } from '../../constans';
import { staticText } from '../../i18n';

import ModalSlider from './ModalSlider.vue';
import Button from '../Button.vue';

export default {
  name: 'Slider',
  components: {
    Slick,
    ModalSlider,
    Button,
  },
  data() {
    return {
      staticText,
      slickOptions: {
        prevArrow: `<button class="slick-prev" type="button">
            <span></span>
          </button>`,
        nextArrow: `<button class="slick-next" type="button">
            <span></span>
          </button>`,
        dots: true,
        customPaging: (slick, index) => {
          if (this.product.img[index].url) {
            return `
            <button type="button" class="slick__control">
              <img src="${this.baseDomainImg}${this.product.img[index].url}" alt />
            </button>`;
          } else {
            return `
            <button type="button" class="slick__control">
              <img src="${require('../../assets/img/product/card__product-slider-previews-img-5.jpg')}" alt />
            </button>`;
          }
        },
      },
      slickExtension: {
        prevArrow: `<button class="slick-prev" type="button">
            <span></span>
          </button>`,
        nextArrow: `<button class="slick-next" type="button">
            <span></span>
          </button>`,
        dots: true,
      },
      url: '',
      showModal: false,
      index: 0,
      baseDomainImg,
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
      product: (state) => state.products.product,
      isLoggedIn: (state) => state.users.userData.isLoggedIn,
    }),
  },
  watch: {
    product: {
      handler() {
        this.reInit();
        this.sliderStatus();
      },
      deep: true,
    },
  },
  created() {
    this.sliderStatus();
    // console.log(this.product);
  },
  methods: {
    async reInit() {
      let currIndex;

      const promiseMain = new Promise(() => {
        if (this.$refs.slick) {
          currIndex = this.$refs.slick.currentSlide();
          this.$refs.slick.destroy();
        }
      });

      promiseMain.then(
        this.$nextTick(() => {
          if (this.$refs.slick) {
            this.$refs.slick.create();
            this.$refs.slick.goTo(currIndex, true);
          }
        }),
      ).catch((e) => console.log(e));
      const promiseExtension = new Promise(() => {
        if (this.$refs.extension) {
          this.$refs.extension.destroy();
        }
      });

      promiseExtension.then(
        this.$nextTick(() => {
          if (this.$refs.extension) {
            this.$refs.extension.create();
          }
        }),
      ).catch((e) => console.log(e));
    },
    sliderStatus() {
      if (this.product.img && Array.isArray(this.product.img)){
        this.product.img.forEach((e) => {
          if (e.video) {
            this.$set(e, 'status', false);
            this.$set(e, 'statusModal', false);
          }
        });
      }
    },
    showModalIndex(index) {
      this.showModal = true;
      this.index = index;
    },
    closeModalEmit() {
      this.showModal = false;
      this.sliderStatus();
    },
    addSet(ids) {
      this.$emit('addSet', ids);
    },
  },
};
</script>

<style lang="scss">
@import '../../../node_modules/slick-carousel/slick/slick.scss';
.card__slider-wrap{
  position: relative;
  & .card__product-made-in{
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 35px;
    height: 80px;
    margin-right: 2px;
    flex-wrap: wrap;
    justify-content: flex-start;
    z-index: 1;
    align-items: center;
    & img {
      max-width: fit-content;
    }
    & p{
      font-size: 9px;
      font-weight: 700;
    }
  }
}

.product-instruction {
  margin-top: 30px;
  margin-left: 70px;
  text-align: center;
  & span {
    padding-right: 5px;
  }
  & a {
    color: $main;
    font-family: 'MagistralC';
    font-weight: bold;
    font-size: 16px;
    &.disable {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
  &.onePhoto {
    margin-left: 0px;
  }
}

.product-extension{
  margin-top: 30px;
  .card__product-extension-header{
    margin-left: 100px;
    margin-right: 30px;
    background: #FFFFFF;
    h3{
      text-align: center;
      padding-top: 5px;
      font-family: 'MagistralC';
    }
  }
}

.card__product-slider {
  //display: flex;
  margin-left: 100px;
  margin-right: 30px;
  position: relative;
  max-width: 455px;
  min-width: 455px;
  width: 100%;

  &.extension{
    .card__product-extension-slider{
      background-color: #fff;
      display: flex;
      align-items: center;
      min-height: 261px;
      justify-content: center;
      width: 100%;
      padding: 20px 40px;
      & img {
        width: 30%;
        max-height: 348px;
      }
      .product{
        width: 30%;
        text-align: center;
        display: block;
        &:last-child{
          max-height: 130px;
        }
        img{
          display: inline-block;
          min-width: 80%;
          max-width: 80%;
          width: 80%;
        }
        p{
          font-family: 'MagistralC';
          //font-size: 18px;
          //line-height: 21px;
          letter-spacing: 0.02em;
          color: #1d1d1d;
          font-weight: bold;
          span{
            display: block;
          }
        }
        .old-price{
          text-decoration-line: line-through;
          color: #9b9b9b;
        }
        .new-price{
          font-size: 16px;
        }
        .btn button{
          margin-top: 28px;
          padding: 8px 12px;
        }
        .red-btn{
          .btn button{
            color: $main;
            background: transparent;
            border: 1px solid $main;
          }
        }
        .not-available{
          display: inline-block;
          margin-top: 28px;
          padding-top: 6px;
        }
      }
      .sign{
        width: 5%;
        text-align: center;
        .circle{
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid $main;
          color: $main;
          box-sizing: border-box;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .slick-arrow {
      margin-top: 0;
    }
    .slick-dots {
      max-height: 300px;
      margin-top: -39px;
    }
  }

  .slick-arrow {
    padding: 10px;
    color: #dcc488;
    margin-top: 40px;
    z-index: 1;
    position: absolute;
    top: 38%;
  }
  .slick-next {
    right: 15px;
    padding-left: 0;
    padding-right: 0px;
  }
  .slick-prev {
    left: 15px;
    padding-left: 0;
    padding-right: 0px;
    width: 23px;
  }
  .slick-next span:before {
    font-family: 'icomoon';
    content: '\e93f';
    font-size: 23px;
  }
  .slick-prev span:before {
    font-family: 'icomoon';
    font-size: 23px;
    content: '\e944';
  }
  .slick-dots {
    position: absolute;
    top: 0;
    padding: 0;
    left: -100px;
    display: block;
    max-height: 575px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    cursor: move;
    scrollbar-color: #262626 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color: #262626, $alpha: 1);
    }
    li {
      width: 68px;
      height: 68px;
      padding: 0px;
      background-color: #fff;
      border: 1px solid transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: #fff;
        border: 1px solid #dcc488;
      }
    }
    & li + li {
      margin-top: 16px;
    }
    .slick-active {
      border: 1px solid #dcc488;
      &:before {
        content: '';
        width: 0px;
        height: 0px;
        background-color: transparent;
      }
    }
    .slick__control {
      max-width: 68px;
      min-width: 68px;
      width: 100%;
      height: 68px;
      & img {
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 57px;
        max-height: 57px;
        text-align: center;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid #fff;
        margin: 0 auto;
      }
    }
  }
  .card__product-slider-img {
    background-color: #fff;
    display: flex;
    align-items: center;
    min-height: 575px;
    justify-content: center;
    width: 100%;
    padding: 112px 40px;
    & img {
      cursor: pointer;
      max-height: 348px;
    }
  }
  .card__product-slider-video {
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 575px;
    width: 100%;
    padding: 112px 40px;
    position: relative;
    & iframe {
      cursor: pointer;
      width: 100%;
      height: 100%;
      border: none;
    }
    &_play-btn {
      background-image: url('../../assets/img/play_button.png');
      background-repeat: no-repeat;
      background-size: cover;
      height: 80px;
      width: 80px;
      top: 50%;
      left: 50%;
      margin-left: -45px;
      margin-top: -30px;
      position: absolute;
      background-position: 50% 50%;
      cursor: pointer;
    }
    & img {
      width: 100%;
      height: auto;
    }
  }
}
.no-previews {
  margin-left: 0px;
  margin-bottom: 0;
}
.module-card__product-inner {
  .card__product-slider {
    .slick-dots {
      max-height: 452px;
    }
  }
}

@media (max-width: 1200px) {
  .product-extension{
    .card__product-extension-header{
      margin-right: 0;
    }
  }
  .card__product-slider {
    margin-right: 0;
    max-width: 468px;
  }
  .card__slider-wrap{
    & .card__product-made-in{
      right: 5px;
      & p{
        font-size: 8px;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 1000px) {
  .module-card__product-inner {
    .card__product-slider {
      .slick-dots {
        max-height: 400px;
      }
    }
  }
  .modal-mask {
    .card__product-slider-video {
      & iframe {
        height: 400px;
      }
    }
    .card__product-slider-img {
      & img {
        max-height: 400px;
      }
    }
  }
}
@media (max-width: 700px) {
  .modal-mask {
    .card__product-slider {
      .slick-dots {
        top: auto;
        bottom: -140px;
        left: 0;
        right: 0;
        display: flex;
        overflow-x: auto;
        & li {
          margin: 10px;
          & + li {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .product-extension{
    .card__product-extension-header{
      margin-left: 0;
    }
  }
  .card__slider-wrap{
    & .card__product-made-in{
      right: 0;
      height: 32px;
      margin-right: 0;
      & img{
        margin-top: -8px;
      }
      & p{
        display: none;
      }
    }
  }
  .product-instruction {
    margin-left: 0px;
  }
  .card__product-slider {
    margin: 0 auto;
    margin-bottom: 100px;

    .slick-dots {
      top: auto;
      bottom: -100px;
      left: 0;
      right: 0;
      display: flex;
      padding-right: 0px;
      overflow-x: auto;
      flex-shrink: 0;
      & li {
        margin: 10px;
        margin-top: 0px;
        & button {
          max-width: 68px;
          min-width: 68px;
          width: 100%;
          height: 68px;
        }
        & + li {
          margin-top: 0px;
        }
      }
    }
    .card__product-slider-img {
      min-height: 400px;
      padding: 0;
      & img {
        max-height: 400px;
      }
    }
    .card__product-slider-video {
      height: 400px;
      padding: 0px;
      & iframe {
        cursor: pointer;
        width: 375px;
        height: 348px;
        border: none;
        margin: 0 auto;
      }
    }
  }
  .no-previews {
    margin-bottom: 0;
  }
  .modal-mask {
    .card__product-slider {
      .slick-dots {
        bottom: -140px;
      }
      .card__product-slider-video {
        & iframe {
          height: 250px;
        }
      }
      .card__product-slider-img {
        & img {
          max-height: 250px;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .card__product-slider {
    max-width: 400px;
    min-width: 400px;

    &.extension {
      max-height: unset;

      .card__product-extension-slider {
        //background-color: #fff;
        display: flex;
        flex-direction: column;
        //align-items: center;
        //min-height: 261px;
        justify-content: center;
        //width: 100%;
        //padding: 20px 40px;
        //& img {
        //  width: 30%;
        //  max-height: 348px;
        //}
        .product {
          width: 80%;
          //text-align: center;
          //display: block;
          //img{
          //  display: inline-block;
          //  min-width: 80%;
          //  max-width: 80%;
          //  width: 80%;
          //}
          p {
            //font-family: 'MagistralC';
            font-size: 16px;
            //line-height: 21px;
            //letter-spacing: 0.02em;
            //color: #1d1d1d;
            //font-weight: bold;
            span {
              display: inline;
            }
          }

          //.old-price{
          //  text-decoration-line: line-through;
          //  color: #9b9b9b;
          //}
          .new-price {
            font-size: 18px;
          }

          .btn button {
            margin-top: 14px;
            //padding: 8px 12px;
          }

          //.red-btn{
          //  .btn button{
          //    color: $main;
          //    background: transparent;
          //    border: 1px solid $main;
          //  }
          //}
          //.not-available{
          //  display: inline-block;
          //  margin-top: 28px;
          //  padding-top: 6px;
          //}
        }

        .sign {
          width: auto;
          margin: 0 0 5px;
          //text-align: center;
          //.circle{
          //  width: 20px;
          //  height: 20px;
          //  border-radius: 50%;
          //  border: 1px solid $main;
          //  color: $main;
          //  box-sizing: border-box;
          //  display: flex;
          //  align-content: center;
          //  align-items: center;
          //  justify-content: center;
          //}
        }
      }

    }

    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }
}
@media (max-width: 440px) {
  .card__product-slider {
    max-width: 360px;
    min-width: 360px;
  }
}
@media (max-width: 420px) {
  .modal-mask {
    .card__product-slider {
      .slick-dots {
        bottom: -100px;
        & li {
          margin: 5px;
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .card__product-slider {
    max-width: 320px;
    min-width: 320px;
    .card__product-slider-img {
      min-height: 320px;
      padding: 0;
      & img {
        max-height: 320px;
      }
    }
    .card__product-slider-video {
      height: 320px;
      padding: 0px;
      & iframe {
        cursor: pointer;
        width: 320px;
        height: 320px;
        border: none;
        margin: 0 auto;
      }
    }
  }
}
@media (max-width: 350px) {
  .card__product-slider {
    max-width: 300px;
    min-width: 300px;
  }
}
@media (max-width: 340px) {
  .card__product-slider {
    .card__product-slider-img {
      min-height: 288px;
      padding: 0;
      & img {
        max-height: 288px;
      }
    }
    .card__product-slider-video {
      height: 288px;
      padding: 0px;
      & iframe {
        cursor: pointer;
        width: 288px;
        height: 288px;
        border: none;
        margin: 0 auto;
      }
    }
  }
}
@media (max-width: 330px) {
  .card__product-slider {
    max-width: 290px;
    min-width: 290px;
  }
}
</style>
