/* eslint-disable import/prefer-default-export */
//export const baseDomain = 'https://dev.alcotec.com.ua/api';
//export const baseDomainImg = 'https://dev.alcotec.com.ua';
//
//export const baseDomain = 'https://dev.alcotec.com.ua/api';
//export const baseDomainImg = 'https://devfront.alcotec.com.ua';

export const baseDomain = 'https://api.alcotec.ua/api';
export const baseDomainImg = 'https://alcotec.ua';

//export const baseDomain = 'https://api.aycapital.cz/api';
//export const baseDomainImg = 'https://aycapital.cz';
