/* eslint-disable no-param-reassign */
import { baseDomain } from '../../constans';

const products = {
  namespaced: true,
  state: { // состояние приложения, данные, которые внутри приложения используются
    product: {
      totalInfo: {
        title: '',
      },
    },
    blockAddToBasket: false,
    isLoading: false,
  },
  getters: { // нужны для того чтобы вынимать какие то данные из общих данных приложения, из state, например если будет сложный объект с данными, чтобы не вытягивать каждый раз весь объект, мы можем здесь создать метод, который будет вытаскивать нам только нужные данные
    productName(state) {
      return state.product.totalInfo.title;
    },
  },
  mutations: { // нужны для того, чтобы описывать методы, которые будут изменять данные нашего приложения
    SET_PRODUCT(state, data) {
      state.product = data;
    },
    CHANGE_LOADING(state) {
      state.isLoading = !state.isLoading;
    },
  },
  actions: {
    async getProduct({ commit, dispatch }, { id, cb, error }) {
      const token = localStorage.alcotecUserInfo
        ? (JSON.parse(localStorage.alcotecUserInfo).user
          ? JSON.parse(localStorage.alcotecUserInfo).user.token
          : false)
        : false;

      fetch(`${baseDomain}/product/${id}`, {
        headers: token ? {
          'auth-token': token,
        } : {},
      }).then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', {
              action: 'products/getProduct',
              id,
              cb,
              error,
            }, { root: true });
          } else {
            commit('SET_PRODUCT', response);
            if (response.totalInfo) {
              response.totalInfo.compare = false;
              response.totalInfo.addedToBasket = 0;
              cb();
            } else {
              error();
            }
          }
        })
        .catch((err) => console.error(err));
    },
  },
};

export default products;
