<template>
  <div class="configurator__position-top login-container">
    <div class="configurator__position-title">
      <form>
        <div class="configurator-title-wrapper">
          <div class="configurator-title">
            {{ staticText.configurateArea.title[actualLang.index] }}
          </div>
          <Search />
        </div>
      </form>
    </div>
    <Categories />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { staticText } from '../i18n';

import Categories from './Configurator/Categories.vue';
import Search from './Configurator/Search.vue';

export default {
  name: 'ConfigurationArea',
  components: {
    Categories,
    Search,
  },
  data() {
    return {
      staticText,
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
    }),
  },
};
</script>

<style lang="scss" scoped>
.configurator-search__wrapper {
  z-index: 1;
  position: relative;
  max-width: 400px;
  width: 100%;
  & button {
    max-width: 445px;
    width: 100%;
    .search.configurator-title-input {
      .icon-close-small {
        margin-right: 0px;
      }
    }
  }
  & .configurator-search__memory {
    & .icon-close-small {
      padding-right: 16px;
    }
    & .title-memory {
      padding-left: 24px;
      color: rgba(29, 29, 29, 0.6);
    }
    & ul {
      margin-top: 24px;
      padding-left: 20px;
      margin-bottom: 0px;
      background: rgba(196, 196, 196, 0.08);
      text-overflow: ellipsis;
      padding-right: 50px;
      & li {
        position: relative;
        & svg {
          margin-right: 24px;
        }
        .icon-close-small {
          position: absolute;
          padding-right: 0px;
          right: -30px;
          top: 10px;
        }

        & .search__text {
          text-overflow: ellipsis;
        }
      }
      & li a {
        padding: 9px 0px;
        color: #1d1d1d;
        font-weight: normal;
        text-align: center;
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
      }
    }
  }
  & .search-value {
    position: absolute;
  }
  & .configurator-title-input {
    max-width: 445px;
    position: relative;
  }
  & .configurator-search__wrapper_result {
    position: absolute;
    top: 30px;
    z-index: 2;
    width: 100%;
    padding: 24px 20px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 4px 4px;
    & li {
      position: relative;
      padding-bottom: 16px;
      & button {
        max-width: none;
        width: auto;
      }
      & a {
        color: #1d1d1d;
      }
      &:last-child {
        padding-bottom: 0px;
      }
    }
    & h4 {
      font-family: 'MagistralC', sans-serif;
      text-decoration: underline;
      max-width: 400px;
      margin-bottom: 10px;
      color: $main;
    }
  }
}
.configurator-search__wrapper .configurator-search__memory {
  padding: 0px 0px;
  padding-top: 20px;
}
.configurator__position-top {
  padding-top: 40px;
}
.login-container {
  max-width: 1650px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  padding-top: 40px;
}
.configurator__position-title {
  & form {
    .configurator-title-wrapper {
      display: flex;
      flex-wrap: wrap;
      .configurator-title {
        font-family: 'MagistralC';
        font-size: 32px;
        line-height: 37px;
        letter-spacing: 0.02em;
        color: #1d1d1d;
        font-weight: bold;
        padding-bottom: 32px;
        margin-right: 40px;
      }
    }
  }
}

.configurator__position-filters {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}
.configurator__position-filters-left {
  max-width: 600px;
  margin-left: -8px;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  & span {
    line-height: 19px;
    color: #9b9b9b;
    display: inline-block;
    padding: 6px 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    margin-left: 8px;
    width: max-content;
    & button {
      background: transparent;
      border: none;
      padding: 0;
      padding-left: 8px;
      outline: none;
      cursor: pointer;
    }
  }
}

.configurator__position-filters-sort {
  line-height: 19px;
  color: #9b9b9b;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & button {
    display: inline-block;
    background: transparent;
    outline: none;
    cursor: pointer;
    line-height: 19px;
    color: #9b9b9b;
    padding: 4px 10px;
    margin-left: 16px;
    border: none;
    border: 1px solid transparent;
    transition: all 0.4s;
    &:hover {
      color: #1d1d1d;
    }
    &.active {
      color: #a12c3a;
      border: 1px solid #a12c3a;
    }
    & + button {
      margin-left: 8px;
    }
  }
  .icon-category-arrows {
    display: inline-block;
    & button {
      margin-left: 8px;
    }
    button[class='icon-category-arrow-up'] {
      &::before {
        color: #9b9b9b;
      }
    }
    button[class='icon-category-arrow-up active'],
    button[class='icon-category-arrow-down active'] {
      font-size: 16px;
      font-weight: bold;
      border: 1px solid #a12c3a;
      padding: 8px;
      &::before {
        color: #a12c3a;
      }
    }
    button[class='icon-category-arrow-up'],
    button[class='icon-category-arrow-down'] {
      font-size: 16px;
      font-weight: bold;
      border: 1px solid #9b9b9b;
      padding: 8px;
    }
  }
}

.configurator__position-selected_goods {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.configurator__position-selected__text {
  font-size: 16px;
  line-height: 22px;
  color: #1d1d1d;
  padding-right: 24px;
}
.configurator__position-selected-reset-btn {
  padding: 0;
}

.configurator__position-title_product {
  display: flex;
  & .icon-arrow-back {
    font-size: 30px;
    margin-top: 3px;
    &:before {
      transition: all 0.3s;
    }
    &:hover:before {
      color: #000;
    }
  }
  .configurator-title {
    font-family: 'MagistralC';
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.02em;
    color: #1d1d1d;
    font-weight: bold;
    padding-bottom: 32px;
    margin-right: 40px;
  }
}

@media (max-width: 810px) {
  .configurator__position-title {
    & form {
      .configurator-title-wrapper {
        .configurator-title {
          padding-bottom: 10px;
        }
        .configurator-title-input {
          max-width: 100%;
        }
      }
    }
  }
}
@media (max-width: 680px) {
  .configurator-search__wrapper {
    max-width: 100%;
    & button {
      max-width: 100%;
      & .configurator-title-input {
        max-width: 100%;
        margin-bottom: 10px;
        .search__input {
          max-width: 100%;
        }
      }
    }
  }
  .configurator__position-title_product {
    flex-wrap: wrap;
  }
}
@media (max-width: 520px) {
  .icon-category-arrows {
    margin-top: 8px;
  }
  .configurator__position-filters-sort {
    text-align: left;
    & button {
      padding: 2px 5px;
      margin-left: 5px;
      & + button {
        margin-left: 3px;
      }
    }
    .icon-category-arrows {
      & button {
        margin-left: 5px;
      }
      button[class='icon-category-arrow-up active'],
      button[class='icon-category-arrow-down active'] {
        padding: 4px;
        &::before {
          color: #a12c3a;
        }
      }
      button[class='icon-category-arrow-up'],
      button[class='icon-category-arrow-down'] {
        padding: 4px;
      }
    }
  }
}
@media (max-width: 510px) {
  .configurator__position-title_product {
    .configurator-title {
      font-size: 20px;
      margin-right: 0;
      padding-bottom: 0px;
      text-align: center;
    }
  }
  .configurator__position-filters-sort {
    justify-content: flex-start;
    flex-wrap: wrap;
    & .sort-title {
      order: 1;
    }
    & button {
      padding: 2px 7px;
      margin-left: 5px;
      & + button {
        margin-left: 3px;
      }
    }
    .sort-btns {
      order: 3;
      margin-top: 5px;
      & button:first-child {
        margin-left: 0px;
      }
    }
    .icon-category-arrows {
      order: 2;
      margin-right: 5px;
      & button {
        margin-left: 5px;
      }
      button[class='icon-category-arrow-up active'],
      button[class='icon-category-arrow-down active'] {
        padding: 4px;
        &::before {
          color: #a12c3a;
        }
      }
      button[class='icon-category-arrow-up'],
      button[class='icon-category-arrow-down'] {
        padding: 4px;
      }
    }
  }
}
@media (max-width: 380px) {
  .configurator__position-title {
    & form {
      .configurator-title-wrapper {
        .configurator-title {
          margin-right: 0;
        }
      }
    }
  }
}
.configurator__position-filters {
  flex-direction: column;
}
@media (max-width: 340px) {
  .configurator__position-title {
    & form {
      .configurator-title-wrapper {
        .configurator-title {
          font-size: 29px;
        }
      }
    }
  }
}
</style>
