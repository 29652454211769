<template>
  <div v-if="autoSelectionProducts.length" class="module__auto-selection-product">
    <div class="user-choice" v-if="userProducts.length">
      <div
        v-for="item in userProducts"
        :key="item.id"
        class="models__item_auto-selection"
      >
        <ShortInfoProduct :item="item" />

        <div class="card__product-form">
          <form>
            <check-box
              :id="'auto-selection-' + item.id"
              v-model="item.assortment"
              :name="'compare' + item.id"
              :text="staticText.autoSelection.checkbox[actualLang.index]"
              :active-text="staticText.autoSelection.checkboxActive[actualLang.index]"
            />
          </form>
        </div>
      </div>
    </div>
    <div class="recommendation" v-if="autoProducts.length">
      <div
        v-for="item in autoProducts"
        :key="item.id"
        class="models__item_auto-selection"
      >
        <ShortInfoProduct :item="item" />

        <div class="card__product-form">
          <form>
            <check-box
              :id="'auto-selection-' + item.id"
              v-model="item.assortment"
              :name="'compare' + item.id"
              :text="staticText.autoSelection.checkbox[actualLang.index]"
              :active-text="staticText.autoSelection.checkboxActive[actualLang.index]"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { staticText } from '../../i18n';
import { baseDomainImg } from '../../constans';
import { store } from '../../store';

import ShortInfoProduct from '../ShortInfoProduct.vue';
import CheckBox from '../CheckBox.vue';

export default {
  name: 'AutoSelectionProduct',
  components: {
    ShortInfoProduct,
    CheckBox,
  },
  props: {
  },
  data() {
    return {
      staticText,
      baseDomainImg,
      store,
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
      autoSelectionProducts: (state) => state.autoSelection.autoSelectionProducts,
    }),
    userProducts: function () {
      const products = [];

      this.autoSelectionProducts.forEach((item) => { if (item.assortment) products.push(item) });

      return products;
    },
    autoProducts: function () {
      const products = [];

      this.autoSelectionProducts.forEach((item) => { if (!item.assortment) products.push(item) });

      return products;
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.module__auto-selection-product {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: auto;
  margin-top: 20px;
}
.user-choice{
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $main;
  border-radius: 4px;
  margin-right: 5px;
}
.recommendation{
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #9b9b9b;
  border-radius: 4px;
}
.models__item_auto-selection {
  width: 210px;
  height: auto;
  margin-bottom: 40px;
  margin-left: 17px;
  margin-right: 17px;
  /*position: relative;*/
  border-radius: 1px;
  .models__inner {
    height: 300px;
    ::v-deep .models__item-img{
      max-width: 200px;
      min-width: 200px;
      img{
        max-width: 190px;
        min-width: 190px;
      }
    }
  }
  .models__item_auto-selection-add_project {
    white-space: nowrap;
    /*position: absolute;*/
    font-family: 'MagistralC';
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: #a12c3a;
    background-color: transparent;
    font-weight: bold;
    border: 1px solid #a12c3a;
    border-radius: 4px;
    padding: 12px 12px 10px 12px;
    outline: none;
    cursor: pointer;
    /*bottom: 60px;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    &:active {
      background-color: $black;
      color: $white;
      border: 1px solid $black;
    }
  }
  .card__product-form {
    /*position: absolute;*/
    /*bottom: 24px;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    & form {
      display: flex;
      justify-content: center;
      & input[type='checkbox'] {
        margin-left: 0px !important;
        padding: 0;
        align-self: center;
        cursor: pointer;
      }
      & label {
        padding-left: 11px;
        align-self: center;
        font-size: 13px;
        line-height: 18px;
        color: #1d1d1d;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
}
@media (max-width: 1440px) {
  .models__item_auto-selection {
    width: 190px;
    .models__inner {
      height: 300px;
      ::v-deep .models__item-img{
        max-width: 180px;
        min-width: 180px;
        img {
          max-width: 170px;
          min-width: 170px;
        }

      }
    }
  }
}
@media (max-width: 1300px) {
  .models__item_auto-selection {
    width: 170px;
    .models__inner {
      height: 300px;
      ::v-deep .models__item-img{
        max-width: 160px;
        min-width: 160px;
        img {
          max-width: 140px;
          min-width: 140px;
        }

      }
    }
  }
}
@media (max-width: 1100px) {
  .models__item_auto-selection {
    width: 170px;
    .models__inner {
      height: 300px;
      ::v-deep .models__item-img{
        max-width: 160px;
        min-width: 160px;
        img {
          max-width: 140px;
          min-width: 140px;
        }

      }
    }
  }
  .user-choice{
    margin-right: 0;
    margin-bottom: 10px;
  }
}
@media (max-width: 850px) {
  .models__item_auto-selection {
    width: 100%;
    .models__inner {
      height: 300px;
      ::v-deep .models__item-img{
        max-width: 80%;
        min-width: 80%;
        img {
          max-width: 70%;
          min-width: 70%;
        }
      }
    }
  }
  .user-choice{
    width: 100%;
    .models__item_auto-selection{
      width: calc(50% - 34px);
    }
  }
  .recommendation{
    width: 100%;
    .models__item_auto-selection{
      width: calc(50% - 34px);
    }
  }
}
@media (max-width: 550px) {
  .models__item_auto-selection {
    width: 100%;
    .models__inner {
      height: 300px;
      ::v-deep .models__item-img{
        max-width: 80%;
        min-width: 80%;
        img {
          max-width: 70%;
          min-width: 70%;
        }
      }
    }
  }
  .user-choice{
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
    .models__item_auto-selection{
      width: 100%;
    }
  }
  .recommendation{
    width: 100%;
    .models__item_auto-selection{
      width: 100%;
    }
  }
}
</style>
