import { baseDomain } from '../../constans';

const autoSelection = {
  namespaced: true,
  state: { // состояние приложения, данные, которые внутри приложения используются
    autoSelectionStatus: false,
    autoSelectionProducts: [],
    autoSelectionCriterion: [],
    loginMessage: '',
  },
  mutations: { // нужны для того, чтобы описывать методы, которые будут изменять данные нашего приложения
    SET_AUTO_SELECTION_POPUP(state) {
      state.autoSelectionStatus = !state.autoSelectionStatus;
    },
    SET_AUTO_SELECTION_PRODUCTS(state, data) {
      state.autoSelectionProducts = data;
    },
    CLEAR_AUTO_SELECTION_PRODUCTS(state) {
      state.autoSelectionProducts = [];
    },
    SET_AUTO_SELECTION_CRITERION(state, data) {
      state.autoSelectionCriterion = data;
    },
    SET_LOGIN_MESSAGE(state, data) {
      state.loginMessage = data;
    },
    CLEAR_AUTO_SELECTION_CRITERION(state) {
      state.autoSelectionCriterion = [];
    },
  },
  actions: {
    async getAutoSelectionCriterion(
      { commit, dispatch, state },
      { cb, cat, subcat, brand, type, color, error },
    ) {
      const token = localStorage.alcotecUserInfo &&
        JSON.parse(localStorage.alcotecUserInfo).user &&
        JSON.parse(localStorage.alcotecUserInfo).user.token || '';

      const gid = window.$cookies.get('_ga');

      fetch(`${baseDomain}/auto-assortment-select`, {
        method: 'POST',
        headers: {
          'auth-token': token,
        },
        body: JSON.stringify({
          cat: cat,
          subcat: subcat,
          brand: brand,
          type: type,
          color: color,
          firstBrand: state.firstBrand,
          gid: gid,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch (
              'profiles/refreshToken',
              { action: 'autoSelection/getAutoSelectionCriterion', cb, cat, subcat, brand, type, color, error },
              { root: true },
            );
          } else if (response.success) {
            cb(response);
          } else {
            error(response.message);
          }
        })
        .catch((err) => console.error(err));
    },
    async getAutoSelectionProduct({ commit, dispatch }, {
      cb, options, price, error,
    }) {
      const token = localStorage.alcotecUserInfo &&
        JSON.parse(localStorage.alcotecUserInfo).user &&
        JSON.parse(localStorage.alcotecUserInfo).user.token || '';

      const gid = window.$cookies.get('_ga');

      fetch(`${baseDomain}/auto-assortment`, {
        method: 'POST',
        headers: {
          'auth-token': token,
        },
        body: JSON.stringify({
          options: options,
          price: price,
          gid: gid,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch(
              'profiles/refreshToken',
              { action: 'autoSelection/getAutoSelectionProduct', cb, options, price, error },
              { root: true },
            );
          } else if (response.success) {
            cb(response);
          } else {
            error(response);
          }
        })
        .catch((err) => console.error(err));
    },
    async sendFirstBrand({ commit, dispatch }, { firstBrand, error }) {
      const token = localStorage.alcotecUserInfo &&
        JSON.parse(localStorage.alcotecUserInfo).user &&
        JSON.parse(localStorage.alcotecUserInfo).user.token || '';

      const gid = window.$cookies.get('_ga');

      fetch(`${baseDomain}/auto-assortment-change-brand`, {
        method: 'POST',
        headers: {
          'auth-token': token,
        },
        body: JSON.stringify({
          firstBrand: firstBrand,
          gid: gid,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch(
              'profiles/refreshToken',
              { action: 'autoSelection/sendFirstBrand', firstBrand, error },
              { root: true },
            );
          } else if (!response.success) {
            error(response);
          }
        })
        .catch((err) => console.error(err));
    },
  },
};

export default autoSelection;
