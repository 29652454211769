<template>
  <div class="configurator__position-filters-sort">
    <span class="sort-title">
      {{ staticText.configurator.textSort.title[actualLang.index] }}:
    </span>
    <div class="sort-btns">
      <button :class="{ active: sortBy === 'rating' }" @click="onSortType('rating')">
        {{ staticText.configurator.textSort.rating[actualLang.index] }}
      </button>
      <button :class="{ active: sortBy === 'price' }" @click="onSortType('price')">
        {{ staticText.configurator.textSort.price[actualLang.index] }}
      </button>
      <button :class="{ active: sortBy === 'popular' }" @click="onSortType('popular')">
        {{ staticText.configurator.textSort.popular[actualLang.index] }}
      </button>
    </div>

    <div class="icon-category-arrows">
      <button
        class="icon-category-arrow-up"
        :class="{ active: !isDesc }"
        @click.stop="onSortDesc(false)"
      ></button>
      <button
        class="icon-category-arrow-down"
        :class="{ active: isDesc }"
        @click.stop="onSortDesc(true)"
      ></button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { store } from '../../store';

import { staticText } from '../../i18n';

export default {
  name: 'Sort',
  props: {
    activeFilter: {
      type: Object,
      default: () => {},
    },
    sortRequest: {
      type: String,
      default: () => 'categoryItem/getCategoryItem',
    },
  },
  data() {
    return {
      staticText,
      categoryId: this.$route.params.categoryId,
      sortBy: 'rating',
      isDesc: true,
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
      textSort: (state) => state.settings.settings.configurator.textSort,
      searchTitles: (state) => state.categoryItem.searchTitle,
      sortingState: (state) => state.categoryItem.sortingState,
    }),
  },
  watch: {

  },
  created() {
    this.sortBy = this.sortingState.sortBy;
    this.isDesc = this.sortingState.isDesc;
  },
  methods: {
    onSortType(type) {
      this.sortBy = type;

      store.dispatch(this.sortRequest, {
        cb() {
        },
        sorting: {
          sortBy: this.sortBy,
          isDesc: this.isDesc,
        },
        categoryId: this.categoryId,
        search: this.searchTitles,
        filter: this.activeFilter,
      });
      store.commit('categoryItem/SET_SORTING_STATE', {
        sortBy: this.sortBy,
        isDesc: this.isDesc,
      });
      store.commit('categoryItem/SET_CURRENT_PAGE', 1);
    },
    onSortDesc(desc) {
      this.isDesc = desc;

      store.dispatch(this.sortRequest, {
        cb() {
        },
        sorting: {
          sortBy: this.sortBy,
          isDesc: this.isDesc,
        },
        categoryId: this.categoryId,
        search: this.searchTitles,
        filter: this.activeFilter,
      });
      store.commit('categoryItem/SET_SORTING_STATE', {
        sortBy: this.sortBy,
        isDesc: this.isDesc,
      });
      store.commit('categoryItem/SET_CURRENT_PAGE', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.configurator__position-filters-sort {
  line-height: 19px;
  color: #9b9b9b;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & button {
    display: inline-block;
    background: transparent;
    outline: none;
    cursor: pointer;
    line-height: 19px;
    color: #9b9b9b;
    padding: 4px 10px;
    margin-left: 16px;
    border: none;
    border: 1px solid transparent;
    transition: all 0.4s;
    &:hover {
      color: #1d1d1d;
    }
    &.active {
      color: #a12c3a;
      border: 1px solid #a12c3a;
    }
    & + button {
      margin-left: 8px;
    }
  }
  .icon-category-arrows {
    display: inline-block;
    & button {
      margin-left: 8px;
    }
    button[class='icon-category-arrow-up'] {
      &::before {
        color: #9b9b9b;
      }
    }
    button[class='icon-category-arrow-up active'],
    button[class='icon-category-arrow-down active'] {
      font-size: 16px;
      font-weight: bold;
      border: 1px solid #a12c3a;
      padding: 8px;
      &::before {
        color: #a12c3a;
      }
    }
    button[class='icon-category-arrow-up'],
    button[class='icon-category-arrow-down'] {
      font-size: 16px;
      font-weight: bold;
      border: 1px solid #9b9b9b;
      padding: 8px;
    }
  }
}

@media (max-width: 520px) {
  .configurator__position-filters-sort {
    text-align: left;
    & button {
      padding: 2px 5px;
      margin-left: 5px;
      & + button {
        margin-left: 3px;
      }
    }
    .icon-category-arrows {
      & button {
        margin-left: 5px;
      }
      button[class='icon-category-arrow-up active'],
      button[class='icon-category-arrow-down active'] {
        padding: 4px;
        &::before {
          color: #a12c3a;
        }
      }
      button[class='icon-category-arrow-up'],
      button[class='icon-category-arrow-down'] {
        padding: 4px;
      }
    }
  }
}

@media (max-width: 510px) {
  .configurator__position-filters-sort {
    justify-content: flex-start;
    flex-wrap: wrap;
    & .sort-title {
      order: 1;
    }
    & button {
      padding: 2px 7px;
      margin-left: 5px;
      & + button {
        margin-left: 3px;
      }
    }
    .sort-btns {
      order: 3;
      margin-top: 5px;
      & button:first-child {
        margin-left: 0px;
      }
    }
    .icon-category-arrows {
      order: 2;
      margin-right: 5px;
      & button {
        margin-left: 5px;
      }
      button[class='icon-category-arrow-up active'],
      button[class='icon-category-arrow-down active'] {
        padding: 4px;
        &::before {
          color: #a12c3a;
        }
      }
      button[class='icon-category-arrow-up'],
      button[class='icon-category-arrow-down'] {
        padding: 4px;
      }
    }
  }
}
</style>
