import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Registration from '../views/Registration.vue';
import Profile from '../views/Profile.vue';
import Projects from '../views/Projects.vue';
import Orders from '../views/Orders.vue';
import Training from '../views/Training.vue';
import Configurator from '../views/Configurator.vue';
import Edit from '../views/Edit.vue';
import Product from '../views/Product.vue';
import Catalog from '../views/Catalog.vue';
import Promo from '../views/Promo.vue';
import PromoArchive from '../views/PromoArchive.vue';
import Brands from '../views/Brands.vue';
import Partners from '../views/Partners.vue';
// import Price from '../views/Price.vue';
import Contacts from '../views/Contacts.vue';
// import Services from '../views/Services.vue';
import News from '../views/News.vue';
import Career from '../views/Career.vue';
import AboutCompany from '../views/AboutCompany.vue';
import Project from '../views/Project.vue';
import CategoryProductItem from '../views/CategoryProductItem.vue';
import gateway from '../components/gateway.vue';
import TermsPayment from '../views/TermsPayment.vue';
import Comparison from '../views/Comparison.vue';
import InfoPromo from '../views/InfoPromo.vue';
import StaticPages from '../views/StaticPages.vue';
import SearchPage from '../views/SearchPage.vue';
import PageNotFound from '../views/PageNotFound.vue';

import { store } from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/registration',
    name: 'registration',
    component: Registration,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Регистрация',
          ua: 'Реєстрація',
          en: 'Registration',
        },
      },
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    redirect: { path: '/profile/projects' },
    meta: {
      breadCrumb: {
        name: {
          ru: 'Личный кабинет',
          ua: 'Особистий кабінет',
          en: 'Profile',
        },
      },
      requiresAuth: true,
    },
    children: [
      {
        path: 'projects',
        component: gateway,
        meta: {
          breadCrumb: {
            name: {
              ru: 'Проекты',
              ua: 'Проекти',
              en: 'Projects',
            },
          },
        },
        children: [
          {
            path: '',
            component: Projects,
          },
          {
            path: 'project/:id',
            component: Project,
            meta: {
              breadCrumb: {
                name: {
                  ru: 'Карточка проекта',
                  ua: 'Картка проекту',
                  en: 'Project card',
                },
              },
            },
          },
        ],
      },
      {
        path: 'orders',
        name: 'orders',
        component: Orders,
        meta: {
          breadCrumb: {
            name: {
              ru: 'Заказы поставщику',
              ua: 'Замовлення постачальнику',
              en: 'Orders to supplier',
            },
          },
        },
      },
      {
        path: 'training',
        name: 'training',
        component: Training,
        meta: {
          breadCrumb: {
            name: {
              ru: 'Обучение',
              ua: 'Навчання',
              en: 'Training',
            },
          },
        },
      },
      /*
      {
        path: 'promo',
        name: 'personalPromo',
        component: PersonalPromo,
        meta: {
          breadCrumb: {
            name: {
              ru: 'Персональные акции',
              ua: 'Персональні акції',
              en: 'Personal promo',
            },
          },
        },
      },
      */
      {
        path: 'configurator',
        component: gateway,
        meta: {
          breadCrumb: {
            name: {
              ru: 'Конфигуратор',
              ua: 'Конфігуратор',
              en: 'Configurator',
            },
          },
        },
        children: [
          {
            path: '',
            component: Configurator,
          },
          {
            path: 'category/:categoryId',
            component: gateway,
            meta: {
              breadCrumb: {
                name: {
                  ru: 'Название подкатегории',
                  ua: 'Назва підкатегорії',
                  en: 'Subcategory name',
                },
                dynamic: true,
              },
            },
            children: [
              {
                path: '',
                component: CategoryProductItem,
              },
              {
                path: 'comparison',
                name: 'comparison',
                component: Comparison,
                meta: {
                  breadCrumb: {
                    name: {
                      ru: 'Сравнение товаров',
                      ua: 'Порівняння товарів',
                      en: 'Product comparison',
                    },
                  },
                },
              },
              {
                path: 'product/:productId',
                component: Product,
                meta: {
                  breadCrumb: {
                    name: {
                      ru: 'Продукт',
                      ua: 'Продукт',
                      en: 'Product',
                    },
                    dynamic: true,
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'edit',
        name: 'edit',
        component: Edit,
        meta: {
          breadCrumb: {
            name: {
              ru: 'Редактирование',
              ua: 'Редагування',
              en: 'Edit',
            },
          },
        },
      },
    ],
  },
  {
    path: '/catalog',
    component: gateway,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Каталог',
          ua: 'Каталог',
          en: 'Catalog',
        },
      },
    },
    children: [
      {
        path: '',
        component: Catalog,
      },
      {
        path: 'category/:categoryId',
        component: gateway,
        meta: {
          breadCrumb: {
            name: {
              ru: 'Название подкатегории',
              ua: 'Назва підкатегорії',
              en: 'Subcategory name',
            },
            dynamic: true,
          },
        },
        children: [
          {
            path: '',
            component: CategoryProductItem,
          },
          {
            path: 'product/:productId',
            name: 'product',
            component: Product,
            meta: {
              breadCrumb: {
                name: {
                  ru: 'Продукт',
                  ua: 'Продукт',
                  en: 'Product',
                },
                dynamic: true,
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '/promo',
    component: gateway,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Акции',
          ua: 'Акції',
          en: 'Promo',
        },
      },
    },
    children: [
      {
        path: '',
        component: Promo,
      },
      {
        path: 'infoPromo/:id',
        name: 'infoPromo',
        component: InfoPromo,
        meta: {
          breadCrumb: {
            name: {
              ru: 'Персональные акции',
              ua: 'Персональні акції',
              en: 'Personal promo',
            },
            dynamic: true,
          },
        },
      },
    ],
  },
  {
    path: '/promo-archive',
    component: gateway,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Архив акций',
          ua: 'Архів акцій',
          en: 'Promo archive',
        },
      },
    },
    children: [
      {
        path: '',
        component: PromoArchive,
      },
    ],
  },
  {
    path: '/brands',
    name: 'brands',
    component: Brands,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Бренды',
          ua: 'Бренди',
          en: 'Brands',
        },
      },
    },
  },
  {
    path: '/partners',
    name: 'partners',
    component: Partners,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Партнеры',
          ua: 'Партнери',
          en: 'Partners',
        },
      },
    },
  },
  // TODO страница прайслистов
  /*
  {
    path: '/price',
    redirect: '/page/prices',
    name: 'price',
    component: Price,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Прайс-листы',
          ua: 'Прайс-листи',
          en: 'Prices',
        },
      },
    },
  },
  */
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Контакты',
          ua: 'Контакти',
          en: 'Contacts',
        },
      },
    },
  },
  {
    path: '/about',
    name: 'aboutCompany',
    component: AboutCompany,
    meta: {
      breadCrumb: {
        name: {
          ru: 'О компании',
          ua: 'Про компанію',
          en: 'About',
        },
      },
    },
  },
  {
    path: '/news',
    redirect: '/promo',
    name: 'news',
    component: News,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Новости',
          ua: 'Новини',
          en: 'News',
        },
      },
    },
  },
  {
    path: '/termsPayment',
    name: 'termsPayment',
    component: TermsPayment,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Условия оплаты',
          ua: 'Умови оплати',
          en: 'Terms of payment',
        },
      },
    },
  },
  {
    path: '/career',
    redirect: '/contacts',
    name: 'career',
    component: Career,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Карьера с Alcotec',
          ua: 'Кар`ера з Alcotec',
          en: 'Career with Alcotec',
        },
      },
    },
  },
  /*
  {
    path: '/services',
    name: 'services',
    component: Services,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Сервисы',
          ua: 'Сервіси',
          en: 'Services',
        },
      },
    },
  },
  */
  {
    path: '/page/:alias',
    component: StaticPages,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Страница',
          ua: 'Сторінка',
          en: 'Page',
        },
        dynamic: true,
      },
    },
  },
  {
    path: '/search/:search',
    component: SearchPage,
    meta: {
      breadCrumb: {
        name: {
          ru: 'Поиск',
          ua: 'Пошук',
          en: 'Search',
        },
        dynamic: true,
      },
    },
  },
  {
    path: '*',
    component: PageNotFound,
    meta: {
      breadCrumb: {
        name: {
          ru: '404 ERROR',
          ua: '404 ERROR',
          en: '404 ERROR',
        },
      },
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.path.includes('profile/projects') && to.hash) {
      // const projects = document.querySelector('#projects-table').clientHeight;
      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = document.querySelector('#projects-table').getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top;

      return { x: 0, y: offset };
    }
    if (to.path.includes('/profile/orders') && to.hash) {
      const order = document.querySelector('#order__table').clientHeight;

      return { x: 0, y: order - 150 };
    }
    const el = document.querySelector('#sidebar-open-left');

    if (!el) {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeResolve((to, from, next) => {
  if (to.params.categoryId) {
    store.dispatch('profiles/getCategoryProjects', () => {
      if (to.params.productId) {
        store.commit('products/CHANGE_LOADING');
        store.dispatch('products/getProduct', {
          id: to.params.productId,
          cb() {
            store.commit('products/CHANGE_LOADING');
            next();
          },
          error() {
            next('/404-error');
          },
        });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (localStorage.alcotecUserInfo && Object.keys(localStorage.alcotecUserInfo)) {
    const user = JSON.parse(localStorage.alcotecUserInfo);

    store.commit('users/SET_LOGIN_USER', user);
  }

  if (!window.$cookies.get('cookie_agreement')) {
    store.commit('settings/SET_COOKIE_QUESTION', true);
  } else {
    store.commit('settings/SET_COOKIE_QUESTION', false);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // этот путь требует авторизации, проверяем залогинен ли
    // пользователь, и если нет, перенаправляем на страницу home
    if (!store.getters['users/isLoggedIn']) {
      const { categoryId, productId } = to.params;

      if (to.path === '/profile/configurator') {
        next('/catalog');
      } else if (productId) {
        next({
          path: `/catalog/category/${categoryId}/product/${productId}`,
          replace: true,
          query: to.query,
        });
      } else if (!productId && categoryId) {
        next({
          path: `/catalog/category/${categoryId}`,
          replace: true,
          query: to.query,
        });
      } else {
        next('/404-error');
      }
    } else {
      next();
    }
  } else {
    next(); // всегда так или иначе нужно вызвать next()!
  }
});

export default router;
