<template>
  <!--
  <div>
    <div id="flix-minisite"></div>
    <div id="flix-inpage"></div>
  </div>
  -->
  <div id="flix-inpage"></div>
</template>

<script>

export default {
  name: 'FlixContent',
  props: {
    brand: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    lang: {
      type: String,
      default: () => '',
    },
  },
  mounted() {
    this.flixContent();
  },
  methods: {
    flixContent() {
      if (this.brand !== '' && this.name !== '') {
        if (document.getElementById('flixjs')) {
          document.getElementById('flixjs').remove();
        }

        const flixScript = document.createElement('script');

        flixScript.setAttribute('src', '//media.flixfacts.com/js/loader.js');
        flixScript.setAttribute('id', 'flixjs');
        flixScript.setAttribute('async', '');
        flixScript.setAttribute('data-flix-distributor', '17456');
        flixScript.setAttribute('data-flix-language', this.lang);
        flixScript.setAttribute('data-flix-brand', this.brand);
        flixScript.setAttribute('data-flix-mpn', this.name.replace(/[ () ]/gi, ''));
        flixScript.setAttribute('data-flix-ean', '');
        flixScript.setAttribute('data-flix-sku', '');
        flixScript.setAttribute('data-flix-button', 'flix-minisite');
        flixScript.setAttribute('data-flix-inpage', 'flix-inpage');
        flixScript.setAttribute('data-flix-button-image', '');
        flixScript.setAttribute('data-flix-price', '');
        document.head.appendChild(flixScript);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
