<script>
import { POINT_MARKER_ICON_CONFIG } from './mapSettings';

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      infoWindow: null,
    };
  },

  mounted() {
    const { Marker } = this.google.maps;
    const content = `<p>${this.marker.title}</p><p>${this.marker.phone}</p>`;

    const markerClickHandler = () => {
      this.infoWindow.open(this.map);
    };

    new Marker({
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      icon: POINT_MARKER_ICON_CONFIG,
      title: this.marker.title,
      phone: this.marker.phone,
    })
      .addListener('click', () => markerClickHandler());
    this.infoWindow = new this.google.maps.InfoWindow({
      position: this.marker.position,
      content: content,
    });
  },

  render() {},
};
</script>

<style lang="scss" scoped>


</style>
