<template>
  <div class="registration">
    <div class="custom-container">
      <div class="training__registration-wrapper">
        <TrainingRegistrationTitle />
        <div class="training__registration-inner">
          <TrainingRegistrationForm />
          <TrainingRegistrationRight />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrainingRegistrationTitle from '../components/Training/TrainingRegistrationTitle.vue';
import TrainingRegistrationForm from '../components/Training/TrainingRegistrationForm.vue';
import TrainingRegistrationRight from '../components/Training/TrainingRegistrationRight.vue';


export default {
  name: 'Training',
  components: {
    TrainingRegistrationTitle,
    TrainingRegistrationForm,
    TrainingRegistrationRight,
  },
};
</script>

<style lang="scss">
  .registration {
    background-color: #fff;
  }
  .training__registration {
  }
  .training__registration-wrapper {
    padding-top: 48px;
    padding-bottom: 401px;
  }
  .training__registration-inner {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 1240px) {
    .training__registration-inner {
      flex-direction: column;
    }
  }
  @media (max-width: 920px) {
    .training__registration-wrapper {
      padding-bottom: 48px;
    }
  }
</style>
