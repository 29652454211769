var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card__product-tabs"},[_c('div',{staticClass:"tabs__wrapper"},[_c('div',{staticClass:"tabs"},_vm._l((_vm.staticText.productTabs[_vm.actualLang.index]),function(tab){return _c('div',{key:tab,class:[
          'tab',
          {
            active: _vm.currentTab === tab,
            disable:
              (!_vm.product.features &&
                tab === _vm.staticText.productTabs[_vm.actualLang.index][2]) ||
              (!_vm.product.characteristics &&
                tab === _vm.staticText.productTabs[_vm.actualLang.index][1]),
          } ],on:{"click":function($event){return _vm.setTab(tab)}}},[_vm._v(" "+_vm._s(tab)+" ")])}),0),_c('div',{staticClass:"tab_content"},[(_vm.currentTab === _vm.staticText.productTabs[_vm.actualLang.index][0])?_c('ContentTab',{attrs:{"content":_vm.product.totalDescription}}):_vm._e(),(
          _vm.currentTab === _vm.staticText.productTabs[_vm.actualLang.index][1] &&
            _vm.product.characteristics
        )?_c('ContentTab',{attrs:{"content":_vm.product.characteristics}}):_vm._e(),(
          _vm.currentTab === _vm.staticText.productTabs[_vm.actualLang.index][2] &&
            _vm.product.features
        )?_c('ContentTab',{attrs:{"content":_vm.product.features,"brand":_vm.product.totalInfo.brand,"name":_vm.product.totalInfo.name}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }