<template>
  <router-link
    :to="{
      path: `/profile/configurator/category/${item.categoryId}/product/${item.id}`,
    }"
    class="models__inner"
  >
    <button
      ontouchstart=""
      type="button"
      class="models__close"
      @click.prevent="$emit('deleteModelCompare')"
    ></button>
    <div class="models__item-img">
      <img v-if="productImg" :src="`${baseDomainImg}${productImg}`" alt="" />
      <img v-else :src="`${baseDomainImg}${item.img}`" alt="" />
    </div>

    <RatingStars :rating="item.rating" class="models__item-star" />

    <div class="models__item-title">
      {{ item.title }}
    </div>
    <div v-if="item.sale" class="card__product-price-value-wrapper">
      <div class="card__product-price-value-strikethrough">
        {{ item.priceUAH }}
        <span>грн</span>
      </div>
      <div class="card__product-price-value">
        {{ item.sale }}
        <span>грн</span>
      </div>
    </div>

    <div v-else class="card__product-price-value-wrapper">
      <div class="card__product-price-value">
        {{ item.priceUAH }}
        <span>грн</span>
      </div>
    </div>

    <div class="models__item-subtitle">
      {{ item.availability[actualLang.index] }}
    </div>
  </router-link>
</template>

<script>
import { mapState } from 'vuex';
import { staticText } from '../i18n';
import { baseDomainImg } from '../constans';

import RatingStars from './RatingStars.vue';

export default {
  name: 'ShortInfoProductCompare',
  components: {
    RatingStars,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    productImg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      staticText,
      baseDomainImg,
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
    }),
  },
};
</script>

<style lang="scss">
.models__item.models__item_compare {
  .models__item-add_project {
    display: block;
  }
  .models__inner {
    .models__item-title {
      max-width: inherit;
      padding-bottom: 0;
      margin-bottom: 20px;
      white-space: nowrap;
    }
    .models__close {
      cursor: pointer;
      display: block;
      border: none;
      outline: none;
      background-color: transparent;
      position: absolute;
      top: 22px;
      right: 22px;
      width: 18px;
      height: 18px;
      &:after {
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        background-color: #9b9b9b;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #9b9b9b;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:hover,
      &:focus {
        &:after {
          width: 3px;
        }
        &:before {
          height: 3px;
        }
      }
    }
    .models__item-hover {
      .card__product-form {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        & form {
          display: flex;
          justify-content: center;
          & input[type='checkbox'] {
            margin-left: 0px !important;
            padding: 0;
            align-self: center;
            cursor: pointer;
          }
          & label {
            padding-left: 11px;
            align-self: center;
            font-size: 13px;
            line-height: 18px;
            color: #1d1d1d;
            cursor: pointer;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .models__inner.models__inner_padding {
    padding-bottom: 24px;
  }
}
/* @media (max-width: 1440px) {
  .models__inner {
    max-width: 224px;
    height: 100%;
    .models__inner-wrapper-left {
      .models__inner-protect {
        font-size: 9px;
        line-height: 9px;
        margin-bottom: 5px;
        padding: 4px 8px;
      }
    }
    .models__inner-wrapper-right {
      .models__inner-sale {
        font-size: 9px;
        line-height: 9px;
        margin-bottom: 5px;
        padding: 4px 8px;
      }
    }
    .models__item-img {
      min-height: 140px;
      max-height: 140px;
      & img {
        max-height: 140px;
      }
    }
    .models__item-star {
      padding-bottom: 17px;
    }
    .models__item-title {
      font-size: 16px;
      line-height: 16px;
    }
    .card__product-price-value-wrapper {
      .card__product-price-value-strikethrough {
        font-size: 12px;
        line-height: 12px;
      }
      .card__product-price-value {
        font-size: 15px;
        line-height: 15px;
      }
    }
    .models__item-subtitle {
      font-size: 10px;
      line-height: 14px;
      color: #9b9b9b;
    }
  }
} */
@media (max-width: 440px) {
  .models__inner {
    max-width: 100%;
  }
}
@media (max-width: 434px) {
  .models__item_compare {
    .models__item-title {
      font-size: 15px;
    }
  }
}
</style>
