/* eslint-disable no-param-reassign */
import { baseDomain } from '../../constans';

const pages = {
  namespaced: true,
  state: { // состояние приложения, данные, которые внутри приложения используются
    page: {
      name: {},
      content: {},
      meta: {},
    },
  },
  getters: { // нужны для того чтобы вынимать какие то данные из общих данных приложения, из state, например если будет сложный объект с данными, чтобы не вытягивать каждый раз весь объект, мы можем здесь создать метод, который будет вытаскивать нам только нужные данные
    pageName(state) {
      return state.page.name;
    },
  },
  mutations: { // нужны для того, чтобы описывать методы, которые будут изменять данные нашего приложения
    SET_PAGE(state, data) {
      state.page.name = data.name;
      state.page.content = data.content;
      state.page.meta = data.meta;
    },
  },
  actions: {
    async getPage({ commit }, { alias, cb, error }) {
      fetch(`${baseDomain}/pages/${alias}`)
        .then((response) => response.json())
        .then((response) => {
          if (response.success === false) {
            error();
          } else if (response.success === true) {
            commit('SET_PAGE', response);
            cb();
          }
        })
        .catch((err) => console.error(err));
    },
    async getStaticPageInfo({ commit }, { alias, cb, error }) {
      fetch(`${baseDomain}/static-page-info/${alias}`)
        .then((response) => response.json())
        .then((response) => {
          if (response.success === false) {
            error();
          } else if (response.success === true) {
            cb(response);
          }
        })
        .catch((err) => console.error(err));
    },
  },
};

export default pages;
