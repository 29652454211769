<template>
  <div>
    <div class="module__side-sticker" @click="showTutorialModal">
      <p class="module__side-sticker-text">
        {{ staticText.tutorial.sticker[actualLang.index] }}
      </p>
    </div>
    <TutorialModal v-model="modalVisibility" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { staticText } from '../../i18n';
import 'vue-select/src/scss/vue-select.scss';
import TutorialModal from './TutorialModal.vue';

export default {
  name: 'TutorialButton',
  components: {
    TutorialModal,
  },
  data() {
    return {
      staticText,
      modalVisibility: false,
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
    }),
  },
  methods: {
    showTutorialModal() {
      this.modalVisibility = true;
    },
  },
};
</script>

<style scoped lang="scss">
.module__side-sticker {
  background-color: $main;
  width: 40px;
  height: 115px;
  position: fixed;
  bottom: 140px;
  right: 0;
  border-radius: 10px 0 0 10px;
  font-weight: 600;
  color: $gold;
  font-family: 'MagistralC';
  writing-mode: tb-rl;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  & .module__side-sticker-text {
    letter-spacing: 0.6px;
    margin-top: -0.6px;
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    padding: 16px 0;
    margin-right: 10px;
  }
}
@media print {
  /* здесь будут стили для печати */
  .module__side-sticker {
    display: none !important;
  }
}
</style>
