<template>
  <div class="configurator__position-wrapper-items">
    <div class="models__container">
      <div
        v-for="(content, index) in categoryProjects"
        :key="index"
        class="main-content_item"
        :class="{ 'main-content_item-active': content.check }"
      >
        <div class="main-content-wrapper">
          <button @click="expand(index)">
            <div class="main-content_image">
              <img :src="`${baseDomainImg}${content.img}`" alt />
              <div class="main-content-select-wrapper">
                <div class="main-content_select">
                  {{ content.title[actualLang.index] }}
                </div>
                <span class="main-content_icon icon-category-arrow"></span>
              </div>
            </div>
          </button>
        </div>

        <div :style="{ height: height[index] }" class="main-content-dropdown">
          <div
            v-for="(item, i) in content.items"
            :key="i"
            class="main-content-dropdown-content"
          >
            <a @click="goToCategory(item.id)">
              {{ item.title[actualLang.index] }} {{ `(${item.models})` }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { staticText } from '../../i18n';
import { baseDomainImg } from '../../constans';

export default {
  name: 'Categories',
  data() {
    return {
      height: [],
      staticText,
      mobileView: window.innerWidth <= 980,
      baseDomainImg,
    };
  },
  computed: {
    ...mapState({
      categoryProjects: (state) =>
        state.profiles.profileConfigure.categoryProjects,
      actualLang: (state) => state.settings.actualLang,
    }),
  },
  created() {
    this.$store.dispatch('profiles/getCategoryProjects', () => {});
  },
  methods: {
    goToCategory(id) {
      this.$router.push(`${this.$route.path}/category/${id}`);
    },
    expand(i) {
      if (this.categoryProjects[i].check === false) {
        this.categoryProjects.forEach((e, index) => {
          this.height[index] = '0';
          this.categoryProjects[index].check = false;
        });

        this.categoryProjects[i].check = true;

        this.height[i] = '100%';
      } else {
        this.categoryProjects[i].check = false;

        this.height[i] = '0';
      }
    },
  },
};
</script>

<style lang="scss">
.main-content_item {
  background: #fff;
  margin-bottom: 30px;
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
  max-width: 382px;
  width: 100%;
  max-height: 250px;
  .main-content-wrapper {
    max-height: 250px;
    position: relative;
    & button {
      width: 100%;
      .main-content_image {
        max-height: 250px;
        min-height: 250px;
        position: relative;
        & img {
          max-height: 250px;
        }
        .main-content-select-wrapper {
          position: absolute;
          bottom: 0;
          max-width: 382px;
          width: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
          background-color: #a12c3a;
          padding: 16px;
          transition: all 0.3s;
          &:hover {
            background-color: #80121f;
          }
          .main-content_select {
            font-family: 'MagistralC';
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            color: #ffffff;
            font-weight: bold;
          }
          .main-content_icon {
            margin-left: 16px;
            height: max-content;
            transition: all 0.3s;
          }
        }
      }
    }
  }

  .main-content-dropdown {
    height: 0;
    overflow: hidden;
    width: 100%;
    .main-content-dropdown-content {
      padding-bottom: 10px;
      & a {
        font-size: 13px;
        line-height: 18px;
        color: #1d1d1d;
        transition: all 0.3s;
        &:hover {
          color: #a12c3a;
        }
      }
    }
  }
}
.main-content_item-active {
  .main-content_icon {
    transform: rotate(180deg);
  }
  .main-content-dropdown {
    transition: padding-top 0.5s;
    padding: 24px 24px 14px 24px;
    height: auto !important;
    max-height: 100%;
    overflow-y: auto;
    z-index: 1;
    position: absolute;
    background: #ffffff;

    scrollbar-color: #262626 rgba(0, 0, 0, 0.05);
    scrollbar-width: thin;

    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    &::-webkit-scrollbar {
      width: 4px;
      background-color: rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #262626;
    }

    & .checkbox:last-child {
      margin-bottom: 24px;
    }

    & .checkbox:last-child {
      margin-bottom: 24px;
    }
  }
}
.models__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -15px;
  position: relative;
}

@media (max-width: 1680px) {
  .main-content_item {
    max-width: 364px;
  }
}
@media (max-width: 1600px) {
  .main-content_item {
    max-width: 344px;
  }
}
@media (max-width: 1599px) {
  .main-content_item {
    max-width: 382px;
  }
}
@media (max-width: 1440px) {
  .models__item-hover.guest {
    height: 320px;
  }
  .models__item {
    width: 224px;
    height: 310px;
  }
  .models__item-hover {
    width: 257px;
    height: 420px;
    .models__item-img {
      max-width: 190px;
      width: 100%;
      height: 150px;
      margin-bottom: 18px;
      padding-bottom: 0;
      & img {
        max-height: 150px;
      }
    }
    .models__inner-wrapper-left {
      left: 24px;
    }
    .models__inner-wrapper-right {
      right: 25px;
    }
  }
}
@media (max-width: 1340px) {
  .main-content_item {
    max-width: 357px;
  }
}
@media (max-width: 1300px) {
  .models__item {
    margin-bottom: 20px;
  }
}
@media (max-width: 1280px) {
  .main-content_item-active {
    .main-content-dropdown {
      left: 0px;
    }
  }
}
@media (max-width: 1250px) {
  .models__item {
    margin-bottom: 10px;
  }
}
@media (max-width: 1207px) {
  .models__item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .models__item-hover {
    width: 270px;
    .models__inner-wrapper-left {
      left: 31px;
    }
    .models__inner-wrapper-right {
      right: 31px;
    }
  }
}
@media (max-width: 1024px) {
  .main-content_item {
    max-width: 383px;
    .main-content-wrapper {
      & button {
        .main-content_image {
          .main-content-select-wrapper {
            max-width: 383px;
          }
        }
      }
    }
  }
}
@media (max-width: 980px) {
  .models__item.active {
    height: 410px;
    .models__item-add_project {
      padding: 12px;
    }
    .models__item-add_project,
    .card__product-form {
      display: block;
    }
  }
  .mw-compare-btn {
    div:nth-last-child(3) {
      margin-bottom: 20px;
    }
  }
  .models__item-hover {
    display: none;
  }
}
@media (max-width: 825px) {
  .main-content_item {
    max-width: 354px;
  }
}
@media (max-width: 767px) {
  .main-content_item {
    max-width: 383px;
  }
}
@media (max-width: 731px) {
  .configurator__position-wrapper-items {
    .models__item {
      width: 45%;
      .models__inner {
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 500px) {
  .configurator__position-wrapper-items {
    .models__item {
      width: 100%;
      .models__inner {
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 440px) {
  .models__item {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .main-content_item {
    .main-content-wrapper {
      & button {
        .main-content_image {
          min-height: auto;
        }
      }
    }
  }
}
@media (max-width: 411px) {
  .main-content_item-active {
    .main-content-dropdown {
      left: 0px;
    }
  }
}
</style>
