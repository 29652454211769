<template>
    <v-dialog v-model="showDialog" max-width="520px">
      <div class="module__client-tutorial">
        <a
          class="module__client-tutorial-close"
          @click="showDialog = false"
        ></a>
        <div class="module__client-tutorial-inner">
          <div class="module__client-tutorial-title">
            {{ staticText.tutorial.tutorialTitle[actualLang.index] }}
          </div>
          <iframe width="100%"
                  height="350"
                  src="https://www.youtube.com/embed/videoseries?list=PLbP3MzJnkL8CPlmt02Ly_i_EV-QNnX-Ta"
                  title="Інструкція по роботі з B2B порталом Alcotec"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  v-if="userData.isLoggedIn">
          </iframe>
          <iframe width="100%"
                  height="350"
                  src="https://www.youtube.com/embed/videoseries?list=PLbP3MzJnkL8Al9OwpmO_3qAbf3lcD4D03"
                  title="Ознайомлення з функціоналом B2B Alcotec"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  v-if="!userData.isLoggedIn">
          </iframe>
          <div class="external-link">
            <a
              href="https://www.youtube.com/playlist?list=PLbP3MzJnkL8CPlmt02Ly_i_EV-QNnX-Ta"
              target="_blank"
              v-if="userData.isLoggedIn">
              {{ staticText.tutorial.tutorialExternalUrl[actualLang.index] }}
            </a>
            <a
                href="https://www.youtube.com/playlist?list=PLbP3MzJnkL8Al9OwpmO_3qAbf3lcD4D03"
                target="_blank"
                v-if="!userData.isLoggedIn">
              {{ staticText.tutorial.tutorialExternalUrl[actualLang.index] }}
            </a>
          </div>
        </div>
      </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { staticText } from '../../i18n';
import 'vue-select/src/scss/vue-select.scss';

export default {
  name: 'TutorialModal',
  props: {
    value: Boolean,
  },
  data() {
    return {
      mainPopupTutorial: false,
      tutorialData: {
        type: '',
        text: '',
        url: '',
      },
      staticText,
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
      userData: (state) => state.users.userData,
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.module__client-tutorial {
  background-color: #fff;
  .module__client-tutorial-inner,
  .module__recovery-password-inner,
  .module__thanks-inner {
    padding: 60px 48px 48px 48px;
    .module__client-tutorial-application {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #1d1d1d;
      & span {
        & a {
          font-family: 'MagistralC';
          font-size: 16px;
          line-height: 18px;
          color: #a12c3a;
          padding-left: 16px;
          transition: all 0.3s;
          &:hover {
            color: #1d1d1d;
          }
        }
      }
    }
  }
  .external-link {
    text-align: center;
    & a {
      font-family: 'MagistralC';
      font-size: 18px;
      line-height: 0px;
      color: #a12c3a;
      padding-top: 26px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      text-decoration: revert;
      font-weight: bold;
      &:hover {
        color: #1d1d1d;
      }
    }
  }
  .module__client-tutorial-close {
    cursor: pointer;
    display: block;
    position: relative;
    &::before {
      top: 24px;
      right: 24px;
      content: '\e913';
      font-family: 'icomoon';
      color: #9b9b9b;
      font-size: 32px;
      position: absolute;
    }
    &:hover:before {
      transition: all 0.3s;
      color: #000;
    }
  }
  .module__client-tutorial-title,
  .module__thanks-title {
    font-family: 'MagistralC';
    font-size: 28px;
    line-height: 31px;
    letter-spacing: 0.02em;
    color: #1d1d1d;
    text-align: center;
    padding-bottom: 28px;
    font-weight: bold;
  }
}

@media (max-width: 535px) {
  .module__client-tutorial .module__client-tutorial-inner {
    padding: 46px;
  }
  .module__side-sticker {
    bottom: 140px;
  }
}

@media (max-width: 488px) {
  .module__client-tutorial .module__client-tutorial-inner {
    .module__client-tutorial-application {
      & span {
        display: block;
        & a {
          padding-left: 0px;
        }
      }
    }
  }
}
</style>
