var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoggedIn)?_c('div',{staticClass:"configurator__category"},_vm._l((_vm.models),function(model){return _c('router-link',{key:model.id,attrs:{"to":{
      path: ("/profile/configurator/category/" + (model.categoryId)),
      query: {
        brand: model.name,
      },
    }}},[_vm._v(" "+_vm._s(model.name)+" "),_c('span',[_vm._v(_vm._s(model.amount))])])}),1):_c('div',_vm._l((_vm.models),function(model){return _c('router-link',{key:model.id,attrs:{"to":{
      path: ("/catalog/category/" + (model.categoryId)),
      query: {
        brand: model.name,
      },
    }}},[_vm._v(" "+_vm._s(model.name)+" "),_c('span',[_vm._v(_vm._s(model.amount))])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }