<template>
  <div class="contacts">
    <div class="custom-container">
      <h1 class="about_title">
        {{ brand[actualLang.index] }}
      </h1>
      <p>
        Офіс: Київ, 04119, вул. Дегтярівська 25-А, ЖК "Cosmopolitan", корпус 1,
        секція 3, офіс 413 <br />
        Чекаємо на Вас з 9-00 до 18-00 з понеділка по п'ятницю.<br />
        Дуже просимо час візиту погоджувати заздалегідь, щоб заощадити Ваше
        час на очікування на оформлення документів.<br />
        Склад знаходиться в районі Куренівки. <br />
        Будемо раді чути Вас за телефонами: +38 (044) 500-26-26
        повідомте секретаря, кому або з якого питання Ви телефонуєте, і Вас
        перемикають на менеджера. Або наберіть внутрішній номер Вашого менеджера
        самостійно. Телефон для обговорення питань співробітництва та
        реклами: (044) 500-26-26 (внутр. 206).
      </p>
      <div class="gmap">
        <GoogleMapLoader
          :map-config="mapConfig"
          :api-key="yourApiKey"
        >
          <template slot-scope="{ google, map }">
            <GoogleMapMarker
              v-for="marker in markers"
              :key="marker.id"
              :marker="marker"
              :google="google"
              :map="map"
            />
          </template>
        </GoogleMapLoader>
      </div>
      <div class="contacts-table-title">
        Регіональні представництва
      </div>
      <div class="contacts-table">
        <table border="1" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th>Місто</th>
              <th>ПІБ Керівника</th>
              <th>E-mail</th>
              <th>Телефон</th>
              <th>Адреса</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ДНІПРО</td>
              <td>Попович Сергій</td>
              <td>
                <a href="mailto:dnepr1@alcotec.com.ua">dnepr1@alcotec.com.ua</a>
              </td>
              <td><a href="tel:+380503566412">(050) 356-64-12</a></td>
              <td>
                <a href="https://goo.gl/maps/T1XgZHrJt2t" target="_blank">49044, вул. Гоголя 15-в, офіс 18</a>
              </td>
            </tr>
            <tr>
              <td>ХАРКІВ</td>
              <td>Цисон Максим</td>
              <td>
                <a href="mailto:kharkov@alcotec.com.ua">kharkov@alcotec.com.ua</a>
              </td>
              <td>
                <a href="tel:+380503234787">(050) 323-47-87</a>
                <a href="tel:+380577661097">(057) 766-10-97</a>
              </td>
              <td>
                <a href="https://goo.gl/maps/fbPwy2DTzez" target="_blank">61140, пр. Фонвизина 18, офіс 215</a>
              </td>
            </tr>
            <tr>
              <td>ЛЬВІВ</td>
              <td>Савін Максим</td>
              <td>
                <a href="mailto:max@alcotec.lviv.ua">max@alcotec.lviv.ua</a>
                <a href="mailto:office@alcotec.lviv.ua">office@alcotec.lviv.ua</a>
              </td>
              <td>
                <a href="tel:+380504437731">(050) 443-77-31</a>
              </td>
              <td>
                <a href="https://goo.gl/maps/cJCXWSCnGQq2u6DF8" target="_blank">79053, вул. В. Великого 16, офіс 501</a>
              </td>
            </tr>
            <tr>
              <td>ТЕРНОПІЛЬ</td>
              <td>Ярич Валерій</td>
              <td>
                <a href="mailto:valerij_te@alcotec.lviv.ua">valerij_te@alcotec.lviv.ua</a>
              </td>
              <td>
                <a href="tel:+380958310473">(095) 831-04-73</a>
              </td>
              <td>
                <a href="https://goo.gl/maps/5D9Z4Txcrck" target="_blank">46023, пр. Злуки 4, кв. 78</a>
              </td>
            </tr>
            <tr>
              <td>КРОПИВНИЦЬКИЙ</td>
              <td>Ігнатєв Денис</td>
              <td>
                <a href="mailto:kirovograd@alcotec.com.ua">kirovograd@alcotec.com.ua</a>
              </td>
              <td>
                <a href="tel:+380504180295">(050) 418-02-95</a>
              </td>
              <td>
                <a href="https://goo.gl/maps/QBUb82CoF6U2" target="_blank">25015, вул. Вячеслава Чорновола 1Б</a>
              </td>
            </tr>
            <tr>
              <td>ЧЕРКАССЫ</td>
              <td>Ігнатєв Денис</td>
              <td>
                <a href="mailto:kirovograd@alcotec.com.ua">kirovograd@alcotec.com.ua</a>
              </td>
              <td>
                <a href="tel:+380675205550">(067) 520-55-50</a>
                <a href="tel:+380504180295">(050) 418-02-95</a>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>КРИВИЙ РІГ</td>
              <td>Ігнатєв Денис</td>
              <td>
                <a href="mailto:kirovograd@alcotec.com.ua">kirovograd@alcotec.com.ua</a>
              </td>
              <td>
                <a href="tel:+380504180295">(050) 418-02-95</a>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>ВІННИЦЯ</td>
              <td>Мельничук Павло</td>
              <td>
                <a href="mailto:vinnitsa@alcotec.com.ua">vinnitsa@alcotec.com.ua</a>
              </td>
              <td>
                <a href="tel:+380679648455">(067) 964-84-55</a>
                <a href="tel:+380432550388">(0432) 55-03-88</a>
              </td>
              <td>
                <a href="https://goo.gl/maps/WC1eG2rJERk" target="_blank">21021, вул. 600-річчя 17, корп. 21</a>
              </td>
            </tr>
            <tr>
              <td>ОДЕСА</td>
              <td>Доманюк Сергій</td>
              <td>
                <a href="mailto:odessa@alcotec.com.ua">odessa@alcotec.com.ua</a>
              </td>
              <td>
                <a href="tel:+380503860950">(050) 386-09-50</a>
              </td>
              <td>
                <a href="https://goo.gl/maps/P4s1Wt8UuEC2" target="_blank">65009, вул. Фонтанська дорога 11, офіс 516</a>
              </td>
            </tr>
            <tr>
              <td>ЗАПОРІЖЖЯ</td>
              <td>Коростелів Андрій</td>
              <td>
                <a href="mailto:korostelev@alcotec.com.ua">korostelev@alcotec.com.ua</a>
              </td>
              <td>
                <a href="tel:+380503587026">(050) 358-70-26</a>
              </td>
              <td>
                <a href="https://goo.gl/maps/AZEstYPBtao" target="_blank">69000, вул. Північне шосе 4, кабінет 307</a>
              </td>
            </tr>
            <tr>
              <td>ХЕРСОН, МИКОЛАЇВ</td>
              <td>Ігнатєв Денис</td>
              <td>
                <a href="mailto:kirovograd@alcotec.com.ua">kirovograd@alcotec.com.ua</a>
              </td>
              <td>
                <a href="tel:+380675205550">(067) 520-55-50</a>
                <a href="tel:+380504180295">(050) 418-02-95</a>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>КРЕМЕНЧУК</td>
              <td>Лихонос Дмитро</td>
              <td>
                <a href="mailto:kremenchug@alcotec.com.ua">kremenchug@alcotec.com.ua</a>
              </td>
              <td>
                <a href="tel:+380504459133">(050) 445-91-33</a>
              </td>
              <td>
                <a href="https://goo.gl/maps/eGezk69SRHoMaVNM6" target="_blank">73000, пр. Свободи 54А</a>
              </td>
            </tr>
            <tr>
              <td>ЛУЦЬК</td>
              <td>Ярич Валерій</td>
              <td>
                <a href="mailto:valerij_te@alcotec.lviv.ua">valerij_te@alcotec.lviv.ua</a>
              </td>
              <td>
                <a href="tel:+380958310473">(095) 831-04-73</a>
              </td>
              <td>
                <a href="https://goo.gl/maps/5D9Z4Txcrck" target="_blank">46023, пр. Злуки 4, кв. 78</a>
              </td>
            </tr>
            <tr>
              <td>УЖГОРОД</td>
              <td>Бочкор Антон</td>
              <td>
                <a href="mailto:anton_uz@alcotec.lviv.ua">anton_uz@alcotec.lviv.ua</a>
              </td>
              <td>
                <a href="tel:+380952755967">(095) 275-59-67</a>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>ДОНЕЦЬК</td>
              <td>Кудрявцев Віктор</td>
              <td>
                <a href="mailto:kydryavcev@alcotec.com.ua">kydryavcev@alcotec.com.ua</a>
              </td>
              <td>
                <a href="tel:+380503283633">(050) 328-36-33</a>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { staticText } from '../i18n';
import GoogleMapLoader from '../components/Map/GoogleMapLoader.vue';
import GoogleMapMarker from '../components/Map/GoogleMapMarker.vue';
import { mapSettings, markers } from '../components/Map/mapSettings';

export default {
  name: 'Contacts',
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
  },
  data() {
    return {
      brand: staticText.titles.contactsKiev,
      yourApiKey: 'AIzaSyCWlM6SaDJMovhMfg6B8BcqgGhwKy-Y6po',
      markers,
      mapCenter: { lat: 48.962395, lng: 32.227191 },
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
    }),
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts {
  margin: 0;
  background-color: #ffffff;
  min-height: 32.5vh;
}
.about_title {
  font-family: 'MagistralC';
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #1d1d1d;
  font-weight: bold;
  padding-bottom: 32px;
  padding-top: 32px;
}
p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #1d1d1d;
  padding-bottom: 32px;
}
.gmap{
  width: 100%;
  height: 478px;
  margin-bottom: 32px;
  & ::v-deep .google-map-wrap{
    width: 100%;
    height: 478px;
    & .gm-style-iw-d{
      & div{
        max-width: 250px;
        & p{
          font-size: 14px;
          font-weight: 500;
          padding-bottom: 10px;
          line-height: 18px;
        }
      }
    }
  }
}
.contacts-table-title {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
  color: #1d1d1d;
  padding-bottom: 32px;
}
.contacts-table {
  overflow-x: auto;
  scrollbar-color: #262626 #fff;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: #fff;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #262626;
  }
  padding-bottom: 32px;
  & table {
    width: 100%;
    margin-bottom: 120px;
    & thead {
      & tr {
        height: 54px;
        & th {
          font-family: 'Open Sans';
          font-size: 16px;
          line-height: 26px;
          color: #1d1d1d;
          text-align: center;
          white-space: nowrap;
          padding: 0 15px;
        }
      }
    }
    & tbody {
      & tr {
        height: 54px;
        & td {
          font-family: 'Open Sans';
          font-size: 16px;
          line-height: 26px;
          color: #1d1d1d;
          text-align: center;
          white-space: nowrap;
          padding: 0 15px;
          & a {
            color: #1d1d1d;
            display: block;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
