<template>
  <div class="page">
    <div class="custom-container">
      <h1 class="about_title"> {{ page.name[actualLang.index] }} </h1>
      <div class="static-content" v-html="page.content[actualLang.index]"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { baseDomainImg } from '../constans';
import { store } from '../store';

export default {
  name: 'StaticPages',
  data() {
    return {
      baseDomainImg,
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
      page: (state) => state.pages.page,
    }),
  },
  created() {

  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('pages/getPage', {
      alias: to.params.alias,
      cb() {
        next();
      },
      error() {
        next((vm) => vm.$router.push('/404-error'));
      },
    });
  },
  beforeRouteUpdate(to, from, next) {
    store.dispatch('pages/getPage', {
      alias: to.params.alias,
      cb() {
        next();
      },
      error() {
        next('/404-error');
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.page {
  margin: 0;
  background-color: #ffffff;
  min-height: 32.5vh;
  padding-bottom: 40px;
}
.about_title {
  font-family: 'MagistralC';
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #1d1d1d;
  font-weight: bold;
  padding-bottom: 32px;
  padding-top: 32px;
  text-align: center;
}
p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #1d1d1d;
  padding-bottom: 32px;
}
.static-content{
  & ::v-deep.services {
    margin: 0;
    background-color: #ffffff;
    min-height: 32.5vh;
  }
  & ::v-deep.services-wrapper-table {
    scrollbar-color: #262626 #fff;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      background-color: #fff;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #262626;
    }
  }

  & ::v-deep.services-table-title {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    color: #1d1d1d;
    padding-bottom: 32px;
  }

  & ::v-deep.services-table {
    padding-bottom: 32px;
    scrollbar-color: #262626 #fff;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      background-color: #fff;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #262626;
    }
    & table {
      width: 100%;
      & thead {
        & tr {
          height: 54px;
          & th {
            font-family: 'Open Sans';
            font-size: 16px;
            line-height: 26px;
            color: #1d1d1d;
            text-align: center;
            white-space: nowrap;
            padding: 0 15px;
          }
        }
      }
      & tbody {
        & tr {
          height: 54px;
          & td {
            font-family: 'Open Sans';
            font-size: 16px;
            line-height: 26px;
            color: #1d1d1d;
            text-align: center;
            white-space: nowrap;
            padding: 0 15px;
            & ul {
              padding: 10px 0px;
            }
            &:first-child {
              & ul {
                & li {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
  & ::v-deep.services-text-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & ::v-deep.services-text-bottom-col {
    width: 33.333%;
    & ul {
      padding: 20px 0px;
      & span {
        font-weight: bold;
        font-family: 'Open Sans';
        font-size: 16px;
        line-height: 26px;
        color: #1d1d1d;
        white-space: nowrap;
      }
      & li {
        font-family: 'Open Sans';
        font-size: 16px;
        line-height: 26px;
        color: #1d1d1d;
        white-space: nowrap;
      }
    }
  }
  & ::v-deep.services-text-top {
    padding: 20px 0px;
    & ul {
      & li {
        font-family: 'Open Sans';
        font-size: 16px;
        line-height: 26px;
        color: #1d1d1d;
        padding: 10px 0px;
        & span {
          font-weight: bold;
        }
      }
      & span {
        font-weight: bold;
        font-family: 'Open Sans';
        font-size: 16px;
        line-height: 26px;
        color: #1d1d1d;
      }
    }
  }

  @media (max-width: 900px) {
    & ::v-deep.services-text-bottom-col {
      width: 50%;
    }
  }
  @media (max-width: 560px) {
    & ::v-deep.services-text-bottom-col {
      width: 100%;
    }
  }
}

@media all and (min-width: 1601px) {
  .page{
    & .custom-container{
      max-width: 50%;
    }
  }
}
@media all and (max-width: 1600px) {
  .page{
    & .custom-container{
      max-width: 60%;
    }
  }
}
@media all and (max-width: 1200px) {
  .page{
    & .custom-container{
      max-width: 70%;
    }
  }
  .static-content{
    & ::v-deep.services-table {
      overflow-y: auto;
    }
  }
}
@media all and (max-width: 1023px) {
  .page{
    & .custom-container{
      max-width: 80%;
    }
  }
}
@media all and (max-width: 767px) {
  .page{
    & .custom-container{
      max-width: 100%;
    }
  }
}

@media all and (max-width: 430px) {
  .page {
    padding-bottom: 15px;
  }
  .static-content{
    & ::v-deep ul{
      margin-left: 28px;
    }
  }
}

</style>
