<template>
  <section class="configurator__position">
    <div class="card__container configurator__position-wrapper">
      <ConfigurationArea />
      <RigthSidebar />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

import ConfigurationArea from '../components/ConfigurationArea.vue';
import RigthSidebar from '../components/Configurator/RigthSidebar.vue';

export default {
  name: 'Configurator',
  components: {
    ConfigurationArea,
    RigthSidebar,
  },
  computed: {
    ...mapState({
      userData: (state) => state.users.userData,
    }),
  },
};
</script>

<style lang="scss" scoped>
.configurator__position-wrapper {
  display: flex;
  margin-bottom: 13px;
}
.card__container {
  width: 100%;
}
@media (max-width: 534px) {
  .configurator__position-wrapper {
    margin-bottom: 77px;
  }
}
@media (max-width: 340px) {
  .configurator__position-wrapper {
    display: block;
  }
}
</style>
