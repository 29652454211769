<template>
  <div
    v-click-outside="hideSearchResult"
    class="configurator-search__wrapper"
    @click="toggleSearchResult"
  >
    <button @click.prevent="isActiveMemorySearch = !isActiveMemorySearch">
      <Search
        v-model="searchValue"
        class="configurator-title-input"
        :placeholder="staticText.configurateArea.searchPlaceholder"
        :is-active-memory-search="isActiveMemorySearch"
      />
    </button>
    <div
      v-if="searchResult.categories.length || searchResult.products.length"
      class="configurator-search__wrapper_result"
    >
      <ul>
        <h4 v-if="searchResult.categories && searchResult.categories.length">
          {{ staticText.search.category[actualLang.index] }}
        </h4>
        <li
          v-for="(searchItem, index) in searchResult.categories"
          :key="index"
          :class="{
            'configurator__search-categories': searchResult.products.length,
          }"
          @click.prevent="addToMemorySearch(searchItem)"
        >
          <a @click.prevent="routeOnCategories(searchItem)">
            {{ searchItem.title[actualLang.index] }}
          </a>
        </li>

        <h4 v-if="searchResult.products && searchResult.products.length">
          {{ staticText.search.products[actualLang.index] }}
        </h4>
        <li
          v-for="(searchItem, index) in searchResult.products"
          :key="index + 10"
          @click.prevent="addToMemorySearch(searchItem)"
        >
          <a @click.prevent="routeOnProducts(searchItem)">
            {{ searchItem.title[actualLang.index] }}
          </a>
        </li>
      </ul>
    </div>
    <div
      v-else-if="isActiveMemorySearch"
      class="configurator-search__wrapper_result configurator-search__memory"
    >
      <span class="title-memory">
        {{ staticText.configurateArea.searchTitle[actualLang.index] }}
      </span>
      <ul>
        <li v-for="search in historySearch" :key="search.id">
          <a v-if="search.productId && search.categoryId" @click.prevent="routeOnProducts(search)">
            <searchIcon class="search__icon" />
            <span class="search__text">
              {{ search.title[actualLang.index] }}
            </span>
          </a>
          <a v-else-if="search.categoryId" @click.prevent="routeOnCategories(search)">
            <searchIcon class="search__icon" />
            <span class="search__text">
              {{ search.title[actualLang.index] }}
            </span>
          </a>
          <a v-else-if="search.search" @click.prevent="routeOnSearch(search)">
            <searchIcon class="search__icon" />
            <span class="search__text">
              {{ search.title[actualLang.index] }}
            </span>
          </a>
          <button
            class="icon-close-small"
            @click.prevent="deleteItemHistorySearch(search)"
          ></button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ClickOutside from 'vue-click-outside';

import Search from '../Search.vue';
import { staticText } from '../../i18n';

import searchIcon from '../../assets/img/projects/search.svg?inline';

export default {
  name: 'SearchInput',
  directives: {
    ClickOutside,
  },
  components: {
    searchIcon,
    Search,
  },
  data() {
    return {
      staticText,
      searchValue: '',
      isActiveMemorySearch: false,
      historySearch: [],
    };
  },
  computed: {
    ...mapState({
      actualLang: (state) => state.settings.actualLang,
      userData: (state) => state.users.userData,
      searchResult: (state) => state.categoryItem.search,
    }),
  },
  watch: {
    searchValue: {
      handler: function(value) {
        if (value) {
          this.$store.dispatch('categoryItem/getSearchProductsAndCategories', {
            searchValue: value,
            cb: () => {},
          });
        } else {
          this.$store.commit('categoryItem/CLEAR_SEARCH_RESULT');
        }
      },
    },
  },
  created() {
    this.$store.commit('categoryItem/CLEAR_SEARCH_RESULT');
    this.historySearch = JSON.parse(localStorage.historySearch || '[]');
  },
  methods: {
    routeOnCategories(searchItem) {
      const path = this.userData.isLoggedIn
        ? `/profile/configurator/category/${searchItem.categoryId}`
        : `/catalog/category/${searchItem.categoryId}`;

      this.$store.commit('profiles/CLOSE_SIDEBAR_FILTER');
      this.$router.push(path).catch((err) => {});
      this.$store.commit('categoryItem/SET_CATEGORY_ID', searchItem.categoryId);
      this.$store.commit('categoryItem/CLEAR_SEARCH_RESULT');
      this.isActiveMemorySearch = false;

      this.$store.dispatch('categoryItem/getCategoryItem', {
        cb: (products) => {
          this.$store.commit(
            'categoryItem/SET_TITLE_PRODUCT_ITEM',
            products.subCategory[0].type.plural[this.actualLang.index],
          );
        },
        error: () => {},
        categoryId: searchItem.categoryId,
      });

      this.$store.dispatch('profiles/getSideBarFilters', {
        cb: () => {},
        id: searchItem.categoryId,
      });
    },
    routeOnProducts(searchItem) {
      const path = this.userData.isLoggedIn
        ? `/profile/configurator/category/${searchItem.categoryId}/product/${searchItem.productId}`
        : `/catalog/category/${searchItem.categoryId}/product/${searchItem.productId}`;

      this.$router.push(path).catch((err) => {});
      this.$store.commit('categoryItem/CLEAR_SEARCH_RESULT');
      this.isActiveMemorySearch = false;
    },
    routeOnSearch(searchItem) {
      const path = `/search/${searchItem.search.replace(/\//g, '')}`;

      this.$router.push(path).catch((err) => {});
      this.$store.commit('profiles/CLOSE_SIDEBAR_FILTER');
      this.$store.commit('categoryItem/CLEAR_SEARCH_RESULT');
      this.isActiveMemorySearch = false;
    },
    toggleSearchResult() {
      this.isActiveMemorySearch = true;
    },
    hideSearchResult() {
      this.searchValue = '';
      this.isActiveMemorySearch = false;
    },
    addToMemorySearch(item) {
      try {
        let flag = true;

        this.historySearch.forEach((e) => {
          if (
            e.title[this.actualLang.index] === item.title[this.actualLang.index]
          ) {
            flag = false;

            return;
          }
        });

        if (flag) {
          this.historySearch.unshift(item);
        }

        if (this.historySearch.length > 5) {
          this.historySearch = this.historySearch.slice(0, 5);
        }

        localStorage.historySearch = JSON.stringify(this.historySearch);
      } catch (e) {
        console.error('Очистите LocalStorage.');
      }
    },
    deleteItemHistorySearch(item) {
      this.historySearch.forEach((e, index) => {
        if (
          e.title[this.actualLang.index] === item.title[this.actualLang.index]
        ) {
          this.historySearch.splice(index, 1);
        }
      });

      try {
        localStorage.historySearch = JSON.stringify(this.historySearch);
      } catch (e) {
        alert('Очистите LocalStorage.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.configurator-search__wrapper {
  z-index: 2;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-right: 20px;
  & button {
    max-width: 445px;
    width: 100%;
    .search.configurator-title-input {
      .icon-close-small {
        margin-right: 0px;
      }
    }
  }
  & .configurator-search__memory {
    & .icon-close-small {
      padding-right: 16px;
    }
    & .title-memory {
      padding-left: 24px;
      color: rgba(29, 29, 29, 0.6);
    }
    & ul {
      margin-top: 24px;
      padding-left: 20px;
      margin-bottom: 0px;
      background: rgba(196, 196, 196, 0.08);
      text-overflow: ellipsis;
      padding-right: 50px;
      & li {
        position: relative;
        & svg {
          margin-right: 24px;
        }
        .icon-close-small {
          position: absolute;
          padding-right: 0px;
          right: -30px;
          top: 10px;
        }

        & .search__text {
          text-overflow: ellipsis;
        }
      }
      & li a {
        padding: 9px 0px;
        color: #1d1d1d;
        font-weight: normal;
        text-align: center;
        font-size: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
      }
    }
  }
  & .search-value {
    position: absolute;
  }
  & .configurator-title-input {
    max-width: 445px;
    position: relative;
  }
  & .configurator-search__wrapper_result {
    position: absolute;
    top: 30px;
    z-index: 2;
    width: 100%;
    padding: 24px 20px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 4px 4px;
    & li {
      position: relative;
      padding-bottom: 16px;
      & button {
        max-width: none;
        width: auto;
      }
      & a {
        color: #1d1d1d;
      }
      &:last-child {
        padding-bottom: 0px;
      }
    }
    & h4 {
      font-family: 'MagistralC', sans-serif;
      text-decoration: underline;
      max-width: 400px;
      margin-bottom: 10px;
      color: $main;
    }
  }
}
.configurator-search__wrapper .configurator-search__memory {
  padding: 0px 0px;
  padding-top: 20px;
}

@media (max-width: 980px){
  .configurator-search__wrapper{
    margin-right: 0;
  }
}

@media (max-width: 810px) {
  .configurator__position-title {
    & form {
      .configurator-title-wrapper {
        .configurator-title {
          padding-bottom: 10px;
        }
        .configurator-title-input {
          max-width: 100%;
        }
      }
    }
  }
}
@media (max-width: 680px) {
  .configurator-search__wrapper {
    max-width: 100%;
    & button {
      max-width: 100%;
      & .configurator-title-input {
        max-width: 100%;
        margin-bottom: 10px;
        .search__input {
          max-width: 100%;
        }
      }
    }
  }
  .configurator__position-title_product {
    flex-wrap: wrap;
  }
}
@media (max-width: 520px) {
  .icon-category-arrows {
    margin-top: 8px;
  }
  .configurator__position-filters-sort {
    text-align: left;
    & button {
      padding: 2px 5px;
      margin-left: 5px;
      & + button {
        margin-left: 3px;
      }
    }
    .icon-category-arrows {
      & button {
        margin-left: 5px;
      }
      button[class='icon-category-arrow-up active'],
      button[class='icon-category-arrow-down active'] {
        padding: 4px;
        &::before {
          color: #a12c3a;
        }
      }
      button[class='icon-category-arrow-up'],
      button[class='icon-category-arrow-down'] {
        padding: 4px;
      }
    }
  }
}
@media (max-width: 510px) {
  .configurator__position-title_product {
    .configurator-title {
      font-size: 20px;
      margin-right: 0;
      padding-bottom: 0px;
      text-align: center;
    }
  }
  .configurator__position-filters-sort {
    justify-content: flex-start;
    flex-wrap: wrap;
    & .sort-title {
      order: 1;
    }
    & button {
      padding: 2px 7px;
      margin-left: 5px;
      & + button {
        margin-left: 3px;
      }
    }
    .sort-btns {
      order: 3;
      margin-top: 5px;
      & button:first-child {
        margin-left: 0px;
      }
    }
    .icon-category-arrows {
      order: 2;
      margin-right: 5px;
      & button {
        margin-left: 5px;
      }
      button[class='icon-category-arrow-up active'],
      button[class='icon-category-arrow-down active'] {
        padding: 4px;
        &::before {
          color: #a12c3a;
        }
      }
      button[class='icon-category-arrow-up'],
      button[class='icon-category-arrow-down'] {
        padding: 4px;
      }
    }
  }
}
@media (max-width: 380px) {
  .configurator__position-title {
    & form {
      .configurator-title-wrapper {
        .configurator-title {
          margin-right: 0;
        }
      }
    }
  }
}
.configurator__position-filters {
  flex-direction: column;
}
@media (max-width: 340px) {
  .configurator__position-title {
    & form {
      .configurator-title-wrapper {
        .configurator-title {
          font-size: 29px;
        }
      }
    }
  }
  .configurator__position-wrapper {
    display: block;
  }
}
</style>
