var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('div',{ref:"comparison",staticClass:"comparison"},[_c('div',{staticClass:"card__container compare__wrapper"},[(_vm.userData.isLoggedIn)?_c('aside',{staticClass:"left__sidebar-stub"}):_vm._e(),_c('div',{staticClass:"custom-container "},[_c('div',{staticClass:"configurator__position-top"},[_c('div',{staticClass:"configurator__position-title",attrs:{"id":"configurator__position-title"}},[_c('div',{staticClass:"main__registration-title"},[_c('ArrowGoBack',{attrs:{"back-route-path":("/profile/configurator/category/" + (this.$route.params.categoryId))}}),_vm._v(" "+_vm._s(_vm.staticText.comparison.titleText[_vm.actualLang.index])+" ")],1),_c('div',{class:[
                'sidebar__compare-added-btn',
                { 'sidebar__compare-added-btn-mr': _vm.userData.isLoggedIn } ]},[_c('router-link',{attrs:{"tag":"button","type":"button","to":{
                  path: ("/profile/configurator/category/" + (_vm.$route.params.categoryId)),
                }}},[_c('span',{staticClass:"icon-category-plus"}),_vm._v(" "+_vm._s(_vm.staticText.comparison.addedProduct[_vm.actualLang.index])+" ")])],1)]),_c('div',{class:[
              'configurator__compare-overflow',
              { 'overflow-mr': _vm.userData.isLoggedIn } ]},[_c('div',{staticClass:"table__compare",on:{"scroll":_vm.handlerScrollBottom}},[_c('table',[_c('thead',[_c('tr',{staticClass:"table_head"},[_c('th',{staticClass:"th_head"},[_c('div',{staticClass:"compare__item-param"},[_c('div',{staticClass:"compare__item-param-inner"},[(_vm.products.length)?_c('div',{staticClass:"compare__item-param-title"},[_vm._v(" "+_vm._s(_vm.nameCategory)+" ("+_vm._s(_vm.products.length)+") ")]):_vm._e(),_c('div',{staticClass:"compare__item-param-btns"},[_c('button',{staticClass:"compare__item-param-trash",on:{"click":_vm.deleteAllModelCompare}},[_c('span',{staticClass:"icon-compare-trash"}),_vm._v(" "+_vm._s(_vm.staticText.comparison.clearList[ _vm.actualLang.index ])+" ")]),_c('button',{staticClass:"compare__item-param-print",on:{"click":_vm.print}},[_c('span',{staticClass:"icon-compare-printer"}),_vm._v(" "+_vm._s(_vm.staticText.comparison.ViewForPrinting[ _vm.actualLang.index ])+" ")])]),_c('div',{staticClass:"compare__item-param-radio"},[_c('form',[_c('radio-button',{staticClass:"radio_compare",attrs:{"value":"all","name":_vm.radio,"text":_vm.staticText.comparison.checkBoxAllFields[
                                    _vm.actualLang.index
                                  ]}}),_c('radio-button',{staticClass:"radio_compare",attrs:{"value":"diff","name":_vm.radio,"text":_vm.staticText.comparison
                                    .checkBoxProductDifferences[
                                    _vm.actualLang.index
                                  ]}})],1)])])])]),_vm._l((_vm.products),function(item){return _c('th',{key:item.id,staticClass:"models__item models__item_compare",class:{
                        'two-products': _vm.products.length === 2,
                        'one-products': _vm.products.length === 1,
                      }},[_c('ShortInfoProductCompare',{class:{
                          models__inner_padding: !_vm.userData.isLoggedIn,
                        },attrs:{"item":item.totalInfo,"product-img":item.img[0].url,"compare":true},on:{"deleteModelCompare":function($event){return _vm.deleteModelCompare(item.totalInfo.id)}}}),(_vm.userData.isLoggedIn)?_c('BtnAddToBasket',{staticClass:"models__item-add_project",attrs:{"ontouchstart":"","text":_vm.staticText.btns[5][_vm.actualLang.index],"item":item.totalInfo}}):_vm._e()],1)})],2)]),_c('tbody',_vm._l((_vm.specifications),function(specification,index){return _c('tr',{key:index},[_c('td',{staticClass:"specification-title"},[_vm._v(" "+_vm._s(specification.title)+" ")]),_vm._l((specification.products),function(product,i){return _c('td',{key:i,staticClass:"value",class:{
                        'two-products': _vm.products.length === 2,
                        'one-products': _vm.products.length === 1,
                      }},[_vm._v(" "+_vm._s(product.value[_vm.actualLang.index])+" ")])})],2)}),0)])])])])]),_c('RigthSidebar')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }