<template>
  <aside
    v-if="statusSidebar"
    id="sidebar-open-left"
    class="sidebar__filters-opened"
    :class="{
      active: statusScrollSidebar,
      'sidebar__filters-opened_active-filter': activeCheckboxLength,
    }"
  >
    <button
      class="sidebar__filters-open-btn"
      @click="changeStatusFilterSidebar"
    >
      <svg width="16" height="16" class="sidebar__btn">
        <use xlink:href="/assets/icons/icons.svg#filter-icon" />
      </svg>
    </button>
    <div class="sidebar__filters-opened-wrapper">
      <BaseCheckbox
        v-for="(elem, index) in sideBarFiltersProductItem"
        :key="index"
        :value="elem"
      />
    </div>

    <div
      v-if="activeCheckboxLength"
      class="filter-sidebar__position-selected_goods"
    >
      <div class="filter-sidebar__position-selected__text">
        {{ staticText.configurateArea.filterText[actualLang.index] }}<br/>
        <span>{{ numberProducts.totalNumberOfFilteredItems }}</span>
        {{ staticText.configurateArea.filterTextFrom[actualLang.index] }}
        <span>{{ numberProducts.totalQuantityOfGoods }}</span>
      </div>
      <div class="buttons__wrapper">
        <div
            class="filter-sidebar__position-selected-reset-btn"
            @click="cleanOutFilterAndChangeStatusFilterSidebar"
        >
          <Button
              :text="staticText.configurateArea.buttonClear[actualLang.index]"
          />
        </div>
        <div
            class="filter-sidebar__position-selected-reset-btn"
            @click="changeStatusFilterSidebar"
        >
          <Button
              :text="staticText.configurateArea.buttonApply[actualLang.index]"
          />
        </div>
      </div>
    </div>
  </aside>
  <ClosedFilterConfigurator
    v-else-if="!statusSidebar && sideBarFiltersProductItem.length"
    v-model="statusSidebar"
  />
</template>

<script>
import { mapState } from 'vuex';
import { staticText } from '../i18n';

import BaseCheckbox from './BaseCheckbox.vue';
import ClosedFilterConfigurator from './ClosedFilterConfigurator.vue';
import Button from './Button.vue';

export default {
  name: 'FilterSidebar',
  components: {
    BaseCheckbox,
    ClosedFilterConfigurator,
    Button,
  },
  props: {
    activeCheckboxLength: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusViewSidebar: false,
      statusScrollSidebar: false,
      staticText,
    };
  },
  computed: {
    ...mapState({
      statusSidebar: (state) => state.profiles.profileConfigure.statusSidebar,
      actualLang: (state) => state.settings.actualLang,
      sideBarFiltersProductItem: (state) =>
        state.profiles.profileConfigure.sideBarFiltersProductItem,
      numberProducts: (state) => state.categoryItem.numberProducts,
    }),
  },
  created() {
    this.$store.commit('profiles/CLOSE_SIDEBAR_FILTER');
    const filters = this.$route.query;
    const parseFilter = [];

    for (const item in filters) {
      let slags;

      if (
        item !== 'range-price' &&
        item !== 'performance-cube' &&
        item !== 'mounting-width'
      ) {
        slags = filters[item].split(',');
      } else {
        slags = filters[item].split(',');
        slags[0] = `${item}: ${slags[0]}`;
      }

      parseFilter.push(...slags);
    }

    this.sideBarFiltersProductItem.forEach((e) => {
      if (e.checkboxes) {
        e.checkboxes.map((checkbox) => {
          if (parseFilter.includes(checkbox.slag)) {
            checkbox.check = true;
          }
        });
      } else if (e.range) {
        parseFilter.forEach((filter) => {
          if (filter.indexOf(e.fildName) === 0) {
            const status = filter.split(':')[1].split('-');

            e.range.default[0] = status[0];
            e.range.default[1] = status[1];
          }
        });
      }
    });

    this.handleView();
    window.addEventListener('resize', this.handleView);

    this.handlerScrollSidebar();
    document.addEventListener('scroll', this.handlerScrollSidebar);
  },
  methods: {
    changeStatusFilterSidebar() {
      this.$store.commit('profiles/CHANGE_SIDEBAR_FILTER');
    },
    handleView() {
      this.statusViewSidebar = window.innerWidth >= 980 ? true : false;
    },
    handlerScrollSidebar() {
      const { scrollTop } = document.documentElement;

      if (
        document.getElementById('header') &&
        document.getElementById('header').clientHeight &&
        document.getElementById('breadcrumbs') &&
        document.getElementById('breadcrumbs').clientHeight &&
        document.getElementById('personal__cabinet-top') &&
        document.getElementById('personal__cabinet-top').clientHeight
      ) {
        const header = document.getElementById('header').clientHeight;
        const breadcrumbs = document.getElementById('breadcrumbs').clientHeight;
        const personalCabinetTop = document.getElementById(
          'personal__cabinet-top',
        ).clientHeight;

        this.statusScrollSidebar =
          scrollTop > header + breadcrumbs + personalCabinetTop;
      } else if (
        document.getElementById('header') &&
        document.getElementById('header').clientHeight &&
        document.getElementById('breadcrumbs') &&
        document.getElementById('breadcrumbs').clientHeight
      ) {
        const header = document.getElementById('header').clientHeight;
        const breadcrumbs = document.getElementById('breadcrumbs').clientHeight;

        this.statusScrollSidebar = scrollTop > header + breadcrumbs;
      }
    },
    cleanOutFilter() {
      this.$emit('clean');
    },
    cleanOutFilterAndChangeStatusFilterSidebar() {
      this.$emit('clean');
      this.$store.commit('profiles/CHANGE_SIDEBAR_FILTER');
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar__filters-opened {
  padding-top: 20px;
  margin-top: 8px;
  position: sticky;
  max-width: 320px;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  margin-right: 15px;
  margin-bottom: 8px;
  padding-bottom: 15px;
  z-index: 2;
  height: auto;
  overflow-y: auto;

  scrollbar-color: #262626 rgba(0, 0, 0, 0.05);
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #262626;
  }
}
.sidebar__filters-opened.active {
  top: 0px;
  height: 100vh;
  margin-top: 0px;
  margin-bottom: 0px;
}
.sidebar__filters-opened-mw {
  max-width: 428px;
}

.sidebar__filters-open-btn {
  z-index: 1;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  float: right;
  background-color: #a12c3a;
  padding: 20px 20px;
  border-radius: 10px 0px 0px 10px;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    &:before {
      transition: all 0.3s;
      opacity: 0.8;
    }
  }
}
.filter-sidebar__position-selected_goods {
  display: none;
}
@media (max-width: 1440px) {
  .sidebar__filters-opened {
    margin-right: 0;
  }
}

@media (max-width: 1207px) {
  .sidebar__filters-opened {
    width: 50%;
    max-width: 100%;
    position: fixed;
    left: 0px;
    z-index: 100;
    top: -8px;
    overflow-y: auto;
    height: 100%;
  }
}
@media (max-width: 980px) {
  .filter-sidebar__position-selected_goods {
    position: fixed;
    bottom: 0;
    z-index: 15;
    width: 100%;
    background-color: $main;
    color: #fff;
    padding: 15px 15px 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'MagistralC';
    font-weight: bold;
    .filter-sidebar__position-selected-reset-btn button {
      padding: 5px;
    }
  }
  .sidebar__filters-opened {
    width: 100%;
    .sidebar__filters-open-btn{
      //position: fixed;
    }
  }
  .sidebar__filters-opened_active-filter {
    padding-bottom: 100px;
  }
}

.buttons__wrapper{
  display: flex;
  & > *{
    font-size: 12px;
    &:first-child{
      margin-right: 8px;
    }
  }
}

.sidebar__btn{
  scale: 1.5;
}
</style>
