<template>
  <section class="personal__promotions">
    <div class="custom-container">
      <div v-if="emptyMessage" class="personal__promotions-wrapper">
        <p class="emptyMessage">{{ emptyMessage[actualLang.index] }}</p>
      </div>
      <div v-else class="personal__promotions-wrapper">
        <div class="personal__promotions-header">
          <div v-if="isLoggedIn" class="personal__promotions-title">
            {{ staticText.titles.action[actualLang.index] }}
          </div>
          <div v-else class="personal__promotions-title">
            {{ staticText.headerTexts.menu[1].text[actualLang.index] }}
          </div>
          <div class="personal__promotions-select" :class="{ active: brandSelected}">
            <vSelect
              v-model="selectedBrand"
              :placeholder="staticText.autoSelection.inputsPlaceholders.brand[actualLang.index]"
              :searchable="false"
              :clearable="false"
              :loading="inPending"
              :options="allBrands[actualLang.index]"
              class="module-select"
              @input="sendPromoRequest"
            >
              <span slot="no-options">{{ staticText.autoSelection.noOptions[actualLang.index] }}</span>
            </vSelect>
            <a v-if="selectedBrand" class="clear" @click="clearBrand">&#215;</a>
          </div>
          <div class="personal__promotions-archive">
            <router-link to="/promo-archive" tag="button" class="btn">
              {{ staticText.promotions.btnArchive[actualLang.index] }}
            </router-link>
          </div>
        </div>
        <div
          class="personal__promotions-container"
          :class="{ active: isActive }"
        >
          <div class="personal__promotions-items" :class="{ active: isActive }">
            <div
              v-for="personalPromo in allPromos"
              :key="personalPromo.id"
              class="personal__promotions-item"
              :class="{ active: personalPromo.additionalInfo.open }"
            >
              <div
                class="personal__promotions-item-img"
                @click="$vuetify.goTo(target, options)"
              >
                <a @click="showSidebar(personalPromo)">
                  <img :src="`${baseDomainImg}${personalPromo.img}`" />
                </a>
              </div>
              <div class="personal__promotions-item-inner">
                <div class="personal__promotions-item-date">
                  <div class="personal__promotions-item-date-left">
                    {{ personalPromo.date[actualLang.index].start }} -
                  </div>
                  <div class="personal__promotions-item-date-right">
                    {{ personalPromo.date[actualLang.index].finish }}
                  </div>
                </div>
                <div class="personal__promotions-item-title">
                  {{ personalPromo.title[actualLang.index] }}
                </div>
                <div
                  class="personal__promotions-item-more__details"
                  @click="$vuetify.goTo(target, options)"
                >
                  <a @click="showSidebar(personalPromo)">
                    {{ staticText.btns[4][actualLang.index] }}
                  </a>
                </div>
              </div>

              <div
                v-if="personalPromo.additionalInfo.open"
                id="first"
                class="personal__promotion-right"
              >
                <div class="personal__promotion-right-wrapper">
                  <div class="personal__promotion-right-top__img">
                    <div class="personal__promotion-right-top__img-btn">
                      <button @click="closeSidebar(personalPromo)"></button>
                    </div>
                    <img :src="`${baseDomainImg}${personalPromo.img}`" />
                  </div>
                  <div class="personal__promotions-item-text">
                    <div class="personal__promotions-item-date">
                      <div class="personal__promotions-item-date-left">
                        {{ personalPromo.date[actualLang.index].start }} -
                      </div>
                      <div class="personal__promotions-item-date-right">
                        {{ personalPromo.date[actualLang.index].finish }}
                      </div>
                    </div>
                    <div class="personal__promotions-item-title_full">
                      {{ personalPromo.title[actualLang.index] }}
                    </div>
                    <div
                      class="personal__promotions-item-subtitle"
                      v-html="
                        personalPromo.additionalInfo.text[actualLang.index]
                      "
                    ></div>
                    <div
                      v-if="personalPromo.additionalInfo.length"
                      class="personal__promotions-item-action"
                    >
                      {{ staticText.titles.personalPromo[actualLang.index] }}
                      <ul>
                        <li
                          v-for="(items, index) in personalPromo.additionalInfo
                            .shareProduct"
                          :key="index"
                        >
                          {{ items.model }}
                          <router-link
                            :to="{
                              path: `/catalog/category/${items.categoryId}/product/${items.productId}`,
                            }"
                          >
                            {{ staticText.btns[7][actualLang.index] }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="personal__promotion-right-bottom__img">
                    <img :src="personalPromo.additionalInfo.allImg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          v-if="countPage !== 1"
          :page="page"
          :page-count="countPage"
          @paginate="paginate"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import vSelect from 'vue-select';
import { store } from '../store';
import { staticText } from '../i18n';
import { baseDomainImg } from '../constans';

import Pagination from '../components/Pagination.vue';

export default {
  name: 'PersonalPromo',
  components: {
    Pagination,
    vSelect,
  },
  data() {
    return {
      page: 1,
      isActive: false,
      staticText,
      baseDomainImg,
      type: 'selector',
      selector: '#first',
      selections: ['#first'],
      duration: 400,
      offset: 20,
      selectedBrand: '',
      inPending: false,
      emptyMessage: '',
    };
  },
  computed: {
    ...mapState({
      allPromos: (state) => state.personalPromo.personalPromo,
      allBrands: (state) => state.personalPromo.allBrands,
      countPage: (state) => state.personalPromo.countPage,
      actualLang: (state) => state.settings.actualLang,
      isLoggedIn: (state) => state.users.userData.isLoggedIn,
    }),
    target() {
      const value = this[this.type];

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(value)) return Number(value);
      else return value;
    },
    options() {
      return {
        selector: this.selector,
        duration: this.duration,
        offset: this.offset,
      };
    },
    brandSelected() {
      if (this.selectedBrand !== '' && this.selectedBrand !== undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.page = this.$route.hash
      ? parseInt(this.$route.hash.split('#')[1], 10)
      : 1;
    this.selectedBrand = this.$route.hash
      ? this.$route.hash.split('&')[1] : '';

    store.dispatch('personalPromo/getPersonalPromo', {
      page: this.page,
      brand: this.selectedBrand,
      cb: (response) => {
        const activePromo = Number(this.$route.query.promo_id);

        response.personalPromo.forEach((promo) => {
          if (activePromo === promo.id) {
            this.showSidebar(promo);
          }
        });
      },
      error: (response) => {
        if (response.availability) {
          if (response.countActions < 1) {
            this.selectedBrand = '';
          }
          this.page = 1;
          this.inPending = false;
          this.sendPromoRequest();
        } else {
          this.inPending = false;
          this.emptyMessage = response.message;
        }
      },
    });
  },
  methods: {
    paginate(value) {
      switch (value) {
        case 'left':
          this.page--;
          break;
        case 'right':
          this.page++;
          break;

        default:
          this.page = value;
          break;
      }
      this.sendPromoRequest();
    },
    showSidebar(promo) {
      this.allPromos.forEach((e) => {
        e.additionalInfo.open = false;
        this.isActive = false;
      });

      promo.additionalInfo.open = true;
      this.isActive = true;

      this.$router.push(`/promo?promo_id=${promo.id}#${this.page}${(this.selectedBrand ? `&${this.selectedBrand}` : '')}`);
    },
    closeSidebar(promo) {
      this.allPromos.forEach((e) => {
        e.additionalInfo.open = false;
      });

      promo.additionalInfo.open = false;
      this.isActive = false;

      this.$router.push(`/promo#${this.page}${(this.selectedBrand ? `&${this.selectedBrand}` : '')}`);
    },
    sendPromoRequest() {
      this.inPending = true;
      store.dispatch('personalPromo/getPersonalPromo', {
        page: this.page,
        brand: this.selectedBrand,
        cb: () => {
          this.inPending = false;
          if (this.selectedBrand.length !== 0) {
            this.$router.push(`/promo#${this.page}&${this.selectedBrand}`);
            this.isActive = false;
          } else {
            this.$router.push(`/promo#${this.page}`);
            this.isActive = false;
          }
        },
        error: (response) => {
          if (response.availability) {
            if (response.countActions < 1) {
              this.selectedBrand = '';
            }
            this.page = 1;
            this.inPending = false;
            this.sendPromoRequest();
          } else {
            this.inPending = false;
            this.emptyMessage = response.message;
          }
        },
      });
    },
    clearBrand() {
      this.selectedBrand = '';
      this.sendPromoRequest();
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin-top: 14px;
  padding-bottom: 100px;
  & ul {
    & li {
      & a {
        font-family: 'MagistralC';
        font-weight: bold;
      }
    }
  }
}
.emptyMessage{
  margin: 0 0.85vw;
  text-decoration: none;
  font-family: 'MagistralC', sans-serif;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.03em;
  font-weight: bold;
  text-align: center;
}
.personal__promotions-container {
  position: relative;
  &.active {
    min-height: 1700px;
  }
}
.personal__cabinet-top {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.08);
}
.personal__promotions-wrapper {
  padding-top: 48px;
  position: relative;
}
.personal__promotions-header {
  display: flex;
}
.personal__promotions-select{
  margin-left: 50px;
  margin-bottom: 0;
  min-width: 250px;
  font-size: 16px;
  line-height: 22px;
  width: 18%;
  margin-right: .5%;
  color: #1d1d1d;
  display: flex;
  flex-wrap: wrap;
  .module-select{
    width: 100%;
    height: 34px;
    ::v-deep .vs__dropdown-toggle {
      .vs__actions {
        padding-right: 15px;
      }
    }
    &.vs--disabled{
      cursor: not-allowed;
      ::v-deep .vs__dropdown-toggle {
        cursor: not-allowed;
        .vs__selected-options{
          cursor: not-allowed;
          input{
            cursor: not-allowed;
          }
        }
      }
      .vs__actions {
        cursor: not-allowed;
      }
    }
  }
  &.active{
    .module-select{
      width: 100%;
      height: 34px;
      ::v-deep .vs__dropdown-toggle {
        .vs__actions {
          padding-right: 25px;
          & .vs__spinner {
            right: 13px;
          }
        }
      }
    }
    .clear{
      margin-left: -18px;
      justify-self: center;
      font-size: 18px;
      line-height: 33px;
      z-index: 2;
      &:hover{
        color: $main;
      }
    }
  }
}
.personal__promotions-title {
  font-family: 'MagistralC';
  font-size: 32px;
  line-height: 37px;
  letter-spacing: 0.02em;
  color: #1d1d1d;
  font-weight: bold;
  padding-bottom: 32px;
}
.personal__promotions-archive{
  justify-self: flex-end;
  margin-left: auto;
  button.btn {
    background-color: #A12C3A;
    border-radius: 4px;
    outline: none;
    border: none;
    font-family: 'MagistralC';
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: #ffffff;
    padding: 12px 30px;
    cursor: pointer;
    font-weight: bold;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      background-color: #262626;
    }
  }
}
.personal__promotion-inner {
  display: flex;
  flex-wrap: wrap;
}
.personal__promotion-left {
  width: 46%;
  padding-right: 15px;
}
.personal__promotions-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: space-between;
  &.active {
    width: 56%;
  }
}
.personal__promotions-item {
  background-color: #fff;
  max-width: 382px;
  width: 100%;
  margin: 0 15px;
  margin-bottom: 40px;
  border: 1px solid transparent;

  &.active {
    border: 1px solid #a12c3a;
  }
}
.personal__promotions-item-inner {
  padding: 24px;
  max-height: 202px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.personal__promotions-item-img {
  & a {
    display: flex;
    justify-content: center;
    max-width: 382px;
    max-height: 235px;
    margin: 0 auto;
  }
  & img {
    max-height: 235px;
  }
}
.personal__promotions-item-text {
  max-width: 555px;
}

.personal__promotions-item-date {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-transform: uppercase;
  color: #dcc488;
  padding-bottom: 16px;
  display: flex;
  .personal__promotions-item-date-left {
    padding-left: 30px;
    position: relative;
    white-space: nowrap;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      font-family: 'icomoon';
      content: '\e947';
      font-size: 24px;
    }
  }
  .personal__promotions-item-date-right {
    padding-left: 5px;
    white-space: nowrap;
  }
}
.personal__promotions-item-title {
  font-family: 'MagistralC';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  min-height: 68px;
  line-height: 34px;
  letter-spacing: 0.02em;
  color: #1d1d1d;
  margin-bottom: 24px;
  text-align: left;
  max-width: 463px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  &_full{
    font-family: 'MagistralC';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.02em;
    text-overflow: clip;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: auto;
    max-height: max-content;
    padding-bottom: 16px;
  }
}
.personal__promotions-item-more__details {
  & a {
    font-family: 'MagistralC';
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: #a12c3a;
    font-weight: bold;
    position: relative;
    padding-left: 76px;
    transition: all 0.3s;
    &:hover {
      color: #721e27;
    }
    &::before {
      position: absolute;
      content: '';
      top: 7px;
      left: 0;
      width: 60px;
      height: 1px;
      background-color: #a12c3a;
    }
  }
}
.personal__promotion-right {
  width: 38%;
  display: flex;
  top: 0;
  right: 0;
  position: absolute;
  background-color: #fff;
  padding-bottom: 30px;
  max-height: calc(100% - 40px);
}
.personal__promotion-right-wrapper {
  background-color: #fff;
  padding: 30px;
  padding-bottom: 0px;
  overflow-y: auto;
  scrollbar-color: #262626 #fff;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #262626;
  }

  & .checkbox:last-child {
    margin-bottom: 24px;
  }
}
.personal__promotion-right-top__img,
.personal__promotion-right-bottom__img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 555px;
  max-height: 540px;
  width: 100%;
}
.personal__promotion-right-top__img {
  margin-bottom: 30px;
}
.personal__promotion-right-top__img-btn {
  position: absolute;
  top: 30px;
  left: 30px;
  & button {
    background: rgba(255, 255, 255, 0.85);
    border-radius: 4px;
    width: 56px;
    height: 56px;
    position: relative;
    cursor: pointer;
    outline: none;
    border: none;
    transition: all 0.3s;
    &:hover {
      background: rgba(255, 255, 255, 1);
    }
    &::before {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      font-family: 'icomoon';
      content: '\e913';
      font-size: 32px;
      color: #9b9b9b;
    }
  }
}

.personal__promotions-item-subtitle,
.personal__promotions-item-action {
  font-size: 18px;
  line-height: 28px;
  color: #1d1d1d;
  padding-bottom: 32px;
  & p span {
    font-size: 18px !important;
    line-height: 28px;
    color: #1d1d1d;
    padding-bottom: 32px;
  }
  ::v-deep .take-gift{
    margin: 20px auto 0;
    .title-gift {
      background: $main;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border: 2px solid $main;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 2;
      color: $gold;
      @media (max-width: 415px) {
        font-size: 16px;
      }
    }
    .take-gift-content.tbl {
      display: flex;
      flex-direction: column;
      padding: 0;
      border-left: 2px solid $main;
      border-right: 2px solid $main;
      border-bottom: 2px solid $main;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      .row {
        width: 100%;
        display: flex;
        min-height: 50px;
        vertical-align: middle;
        margin: 0;
        &:first-child{
          .cell {
            border-top: none;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &:last-child{
          border-bottom: none;
          .cell{
            border-bottom: none;
          }
        }
        .cell {
          flex: 4;
          border: 1px solid $main;
          text-align: center;
          padding: 5px;
          box-sizing: border-box;
          line-height: 1.8;
          white-space: break-spaces;
          font-size: 16px;
          @media (max-width: 415px) {
            font-size: 14px;
          }
          &:first-child{
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
          &.span-1 {
            flex: 1;
          }
          &.span-2 {
            flex: 2;
          }
          &.span-3 {
            flex: 3;
          }
          &.span-4 {
            flex: 4;
          }
          &.span-5 {
            flex: 5;
          }
          &.span-4-5 {
            flex: 8 12px;
          }
          &.span-3-4 {
            flex: 8 12px;
          }
          &.span-3-5 {
            flex: 12 24px;
          }
          &.red {
            color: $main;
          }
          &.yellow {
            color: $main;
          }
          &.through {
            text-decoration: line-through;
          }
          &.fat {
            font-weight: 700;
          }
          &.big {
            font-size: 150%;
          }
          & a {
            color: #0000EE;
            text-decoration: underline;
          }
        }

      }
    }
  }

}
.personal__promotions-item-subtitle {
  overflow-x: auto;
  scrollbar-color: #262626 #fff;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 4px;
    background-color: rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #262626;
  }
}
.personal__promotions-item-action {
  padding-bottom: 47px;
  & ul {
    padding-left: 20px !important;
    list-style: decimal !important;
    & li {
      padding-top: 24px;
      padding-left: 16px;
      & a {
        font-family: 'MagistralC';
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #a12c3a;
        font-weight: bold;
        padding-left: 24px;
        transition: all 0.3s;
        &:hover {
          color: #721e27;
        }
      }
    }
  }
}

@media (max-width: 1647px) {
  .personal__promotions-items {
    justify-content: space-evenly;
  }
}
@media (max-width: 1500px) {
  .personal__promotions-items {
    &.active {
      width: 50%;
    }
  }
  .personal__promotion-right {
    width: 50%;
  }
}
@media (max-width: 1008px) {
  .personal__promotions-title {
    text-align: center;
  }
  .personal__promotions-header {
    flex-direction: column;
  }
  .personal__promotions-select {
    align-self: center;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .personal__promotions-archive{
    justify-self: center;
    align-self: center;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .personal__promotions-item-action {
    & ul {
      & li {
        & a {
          padding-left: 0px;
          display: block;
          margin-top: 10px;
          text-align: left;
        }
      }
    }
  }
}
@media (max-width: 823px) {
  .personal__promotions-items {
    margin-left: 0;
    margin-right: 0px;
    justify-content: center;
    &.active {
      width: 100%;
    }
  }
  .personal__promotions-item {
    max-width: 100%;
    position: relative;
    .personal__promotions-item-img {
      max-width: 100%;
      & a {
        max-width: 100%;
      }
    }
    .personal__promotions-item-title {
      max-width: 100%;
    }
  }
  .personal__promotion-right {
    max-height: 100%;
    z-index: 1;
    width: 100%;
    box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.1);
  }
  .personal__promotion-right-wrapper {
    width: 100%;
  }
  .personal__promotion-right-top__img {
    max-width: 100%;
  }
}
@media (max-width: 509px) {
  .personal__promotion-right-top__img-btn {
    top: 5px;
    left: 5px;
    & button {
      width: 40px;
      height: 40px;
      &:before {
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 500px) {
  .personal__promotions-item {
    .personal__promotions-item-img {
      & a {
        max-height: 200px;
        & img {
          max-height: 200px;
        }
      }
    }
  }
}
@media (max-width: 415px) {
  .personal__promotions-item-date {
    font-size: 12px;
  }
  .personal__promotions-item-inner {
    max-height: 220px;
    padding: 15px;
  }
}
@media (max-width: 367px) {
  .personal__promotions-item {
    margin-left: 0px;
    margin-right: 0px;
  }
  .personal__promotions-item-title {
    font-size: 20px;
    &_full{
      font-size: 20px;
    }
  }
  .personal__promotions-title {
    font-size: 27px;
  }
  .personal__promotions-item-action {
    & ul {
      & li {
        & a {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
