import colorPalette from './colorPalette';

const {
  COLOR_LANDSCAPE,
  COLOR_BORDERS,
  COLOR_WATER,
  COLOR_LINE,
  COLOR_POINT_FILL,
  COLOR_SELECTED_POINT,
} = colorPalette;

const COLORS = {
  BORDERS: COLOR_BORDERS,
  LANDSCAPE: COLOR_LANDSCAPE,
  LINE: COLOR_LINE,
  POINT: COLOR_SELECTED_POINT,
  POINT_FILL: COLOR_POINT_FILL,
  WATER: COLOR_WATER,
};

const POINT_MARKER_ICON_CONFIG = {

  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
  strokeOpacity: 0.7,
  strokeWeight: 4,
  strokeColor: '#A12C3A',
  fillColor: COLORS.POINT_FILL,
  fillOpacity: 0.7,
  scale: 1.5,

  // url: '@/assets/map/marker.png',
};

const LINE_SYMBOL_CONFIG = {
  path: 'M 0,-2 0,2',
  strokeOpacity: 1,
  strokeWeight: 2,
  scale: 1,
};

const LINE_PATH_CONFIG = {
  clickable: false,
  geodesic: false,
  strokeOpacity: 0,
  strokeColor: COLORS.LINE,
  icons: [
    {
      icon: LINE_SYMBOL_CONFIG,
      repeat: '10px',
    },
  ],
};

const mapSettings = {
  clickableIcons: true,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: 'cooperative',
  backgroundColor: COLORS.LANDSCAPE,
  mapTypeControl: false,
  zoomControlOptions: {
    style: 'SMALL',
  },
  zoom: 6,
  minZoom: 5,
  maxZoom: 15,
  /* styles: [
    {
      featureType: 'landscape',
      stylers: [
        { hue: COLORS.LANDSCAPE },
        { saturation: 50.2 },
        { lightness: -34.8 },
        { gamma: 1 },
      ],
    },
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road.highway',
      stylers: [
        { hue: COLORS.LANDSCAPE },
        { saturation: -19.8 },
        { lightness: -1.8 },
        { gamma: 1 },
      ],
    },
    {
      featureType: 'road.arterial',
      stylers: [
        { hue: COLORS.LANDSCAPE },
        { saturation: 72.4 },
        { lightness: -32.6 },
        { gamma: 1 },
      ],
    },
    {
      featureType: 'road.local',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative.province',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative.locality',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative.province',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative.neighborhood',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.stroke',
      stylers: [{ visibility: 'on' }, { color: COLORS.BORDERS }],
    },
    {
      featureType: 'administrative',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'water',
      stylers: [
        { hue: COLORS.WATER },
        { saturation: -63.2 },
        { lightness: 38 },
        { gamma: 1 },
      ],
    },
  ], */
  styles: [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{
        saturation: 36,
      }, {
        color: '#333333',
      }, {
        lightness: 40,
      }],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [{
        visibility: 'on',
      }, {
        color: '#ffffff',
      }, {
        lightness: 16,
      }],
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [{
        visibility: 'off',
      }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [{
        color: '#fefefe',
      }, {
        lightness: 20,
      }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [{
        color: '#313E49',
      }, {
        lightness: 17,
      }, {
        weight: 2,
      }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{
        color: '#f5f5f5',
      }, {
        lightness: 20,
      }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{
        color: '#f5f5f5',
      }, {
        lightness: 21,
      }],
    },
    {
      featureType: 'poi.business',
      elementType: 'all',
      stylers: [{
        visibility: 'off',
      }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{
        color: '#dedede',
      }, {
        lightness: 21,
      }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [{
        color: '#ffffff',
      }, {
        lightness: 17,
      }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{
        color: '#ffffff',
      }, {
        lightness: 29,
      }, {
        weight: 0.2,
      }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [{
        color: '#ffffff',
      }, {
        lightness: 18,
      }],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [{
        color: '#ffffff',
      }, {
        lightness: 16,
      }],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{
        color: '#f2f2f2',
      }, {
        lightness: 19,
      }],
    },
    {
      featureType: 'transit.station.rail',
      elementType: 'labels',
      stylers: [{
        visibility: 'simplified',
      }, {
        hue: '#fbd402',
      }],
    },
    {
      featureType: 'transit.station.rail',
      elementType: 'labels.text',
      stylers: [{
        visibility: 'on',
      }, {
        lightness: '0',
      }, {
        saturation: '-68',
      }],
    },
    {
      featureType: 'transit.station.rail',
      elementType: 'labels.icon',
      stylers: [{
        visibility: 'on',
      }],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{
        color: '#e9e9e9',
      }, {
        lightness: 17,
      }],
    },
  ],
};

const markers = [
  {
    id: 'Vinnitsa',
    position: { lat: 49.228418, lng: 28.427722 },
    title: 'м. Вінниця, вул. 600-річчя 17, корпус 21',
    phone: '+38 (067) 964-84-55',
  },
  {
    id: 'Zaporozhe',
    position: { lat: 47.867436, lng: 35.114502 },
    title: 'м. Запоріжжя, вул. Північне шосе 4, офіс 307',
    phone: '+38 (050) 358-70-26',
  },
  {
    id: 'Dnepr',
    position: { lat: 48.457395, lng: 35.053237 },
    title: 'м. Дніпро, вул. Гоголя 15В, офіс 18',
    phone: '+38 (050) 356-64-12',
  },
  {
    id: 'Kirovograd',
    position: { lat: 48.516218, lng: 32.270119 },
    title: 'м. Кропивницький, вул. Вячеслава Чорновола 1Б',
    phone: '+38 (050) 418-02-95',
  },
  {
    id: 'Kremenchyk',
    position: { lat: 49.087089, lng: 33.432572 },
    title: 'м. Кременчук, пр. Свободи, 54А',
    phone: '+38 (050) 445-91-33',
  },
  {
    id: 'Kryvyi Rih',
    position: { lat: 47.959969, lng: 33.422871 },
    title: 'м. Кривий Ріг',
    phone: '+38 (050) 418-02-95',
  },
  {
    id: 'Lvov',
    position: { lat: 49.810784, lng: 24.062258 },
    title: 'м. Львів, вул. В. Великого 16, офіс 501',
    phone: '+38 (050) 443-77-31',
  },
  {
    id: 'Odessa',
    position: { lat: 46.445868, lng: 30.742100 },
    title: 'м. Одеса, вул. Фонтанська дорога 11, офіс 516',
    phone: '+38 (050) 386-09-50',
  },
  {
    id: 'Kharkov',
    position: { lat: 49.942180, lng: 36.272001 },
    title: 'м. Харків, пр. Фонвізіна 18, офіс 215',
    phone: '+38 (050) 323-47-87',
  },
  {
    id: 'Herson',
    position: { lat: 46.641818, lng: 32.609380 },
    title: '',
    phone: '+38 (050) 418-02-95',
  },
  {
    id: 'Cherkasy',
    position: { lat: 49.560338, lng: 32.053858 },
    title: 'м. Черкаси',
    phone: '+38 (050) 418-02-95',
  },
  {
    id: 'Kiev',
    position: { lat: 50.460633, lng: 30.455574 },
    title: 'м. Київ, вул. Дегтярівська 25а, корпус 1, секція 3, офіс 413',
    phone: '+38 (044) 223-85-20',
  },

];

export { mapSettings, LINE_PATH_CONFIG, POINT_MARKER_ICON_CONFIG, markers };
